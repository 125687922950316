import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const UserCurrentStatusApi = async(email:any):Promise<any> => {
   
    try {
        const config = {
            headers: {
                Authorization: await jwtToken(),
                email: email
            }
        }
        return await axios.get(`${envDevUrl}/GetWorksmanAccountRegistartionStatusApi`,config)
    } catch (exception) {
        console.log(exception)
        throw exception
    }
}