
import { useState, useEffect } from 'react'
import { UserDashboardComp } from "../basmodule";
import { useSelector, useDispatch } from "react-redux";
import { AllJobsApi } from "../api/AllJobsApi";
import { WorksmanJobListingReviewsComp } from "../components/ListOfJobsComponents/WorksmanJobListingReviewsComp";
import { CustomerDetailsComp } from "../components/ListOfJobsComponents/CustomerDetailsComp";
import { GetJobListingAction } from "../actions/JobListingAction"
import { getSingleUserDataAction } from '../actions/SingleUserDataAction';
import { dateHelpers, subStrDateHelpers } from '../helper/dateHelpers';
import { WorksmanJobListingReviewsHelper } from '../helper/WorksmanJobListingReviewsHelper';
import { getJobsBasedOnMonthsApi } from '../api/getJobsBasedOnMonthsApi';
import { GetJobsMonthWiseAction } from '../actions/MonthWiseJobsAction';
import { FilterDatesAndSelectComp } from '../components/ListOfJobsComponents/FilterDatesAndSelectComp';
import { GetChatMessageApi } from '../api/GetChatMessageApi';
import { GetChatConversationAction } from '../actions/ChatConversationAction';

export const ListOfJobsPage = () => {

    // states
    const [open, setOpen] = useState(false);
    const [bookingDate,setBookingDate] = useState(`${dateHelpers('YYYY-MM-DD')}`)
    const [jobType, setJobType] = useState("")
    const [oldMonth, setOldMonth] = useState(2);
    const [previousMonth, setPreviousMonth] = useState(1);
    const [currentMonth, setCurrentMonth] = useState(0);
    const [futureMonth, setFutureMonth] = useState(-1);

    const monthsWithYear = [oldMonth, previousMonth, currentMonth, futureMonth]

    const { status } = useSelector((state: any) => state.UsersStatus)
    const { joblists } = useSelector((state: any) => state.bookingLists)
    const { reviews } = useSelector((state: any) => state.bookingLists)
    const { messages } = useSelector((state: any) => state.ChatConversationReducer);
    const { single_user_data } = useSelector((state: any) => state.singleUserData)

    console.log("joblistsjoblistsjoblists",joblists)


    const dispatch = useDispatch()

    const selectedMonth = async (monthandyear: any) => {
        let monYear = subStrDateHelpers('YYYY-MMM', monthandyear, 'month', 'YYYY-MM-DD')
        try {
            setBookingDate(subStrDateHelpers('YYYY-MM-DD', monthandyear, 'month', 'YYYY-MM-DD'))
            
            setJobType("")

            if (status.length !== 0) {
                const { data }:any = await getJobsBasedOnMonthsApi(status[0].worksman_id, monYear)

                console.log("This are monthd data",data)
                dispatch(GetJobListingAction(JSON.parse(data.data).body))
            }

        } catch (exception) {
            throw exception
        }
    }

    const onChangeMonth = async () => {
        try {
            if (status.length !== 0) {
                const { data } = await AllJobsApi(status[0].worksman_id)

                const { body } = JSON.parse(data.data)
                console.log("This are body",body)
                dispatch(GetJobListingAction(body))
                // dispatch(GetJobListingAction(body))
            }
        } catch (exception) {
            console.log(exception);
            throw exception;
        }
    }

    const handleDrawerOpen = (custoermData: any) => {
        setOpen(true);
        dispatch(getSingleUserDataAction(custoermData))
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getAllJobsFun = async (worksmanId: any, monthYear: any) => {
        try {
            const { data } = await getJobsBasedOnMonthsApi(worksmanId, monthYear)
            console.log("datadatadata",data)
            dispatch(GetJobListingAction(JSON.parse(data.data).body))
        } catch (exception) {
            throw exception
        }
    }

    useEffect(() => {
        if (status.length !== 0)
            getAllJobsFun(status[0].worksman_id, bookingDate)
    }, [status])

    const getMessages = async (bookingId:any) => {
        const { data } = await GetChatMessageApi(bookingId)
        console.log("This is are chatting data",JSON.parse(data.data).body)
        dispatch(GetChatConversationAction(JSON.parse(data.data).body))
    }


    return (
        <UserDashboardComp>
            <div className="job_listing">
                <FilterDatesAndSelectComp
                    setJobType={setJobType}
                    setBookingDate={setBookingDate}
                    jobType={jobType}
                    bookingDate={bookingDate}
                    handleDrawerOpen={handleDrawerOpen}
                    jobDetailFilter={[
                        selectedMonth,
                        onChangeMonth,
                        setPreviousMonth,
                        setCurrentMonth,
                        setOldMonth,
                        setFutureMonth,
                        monthsWithYear,
                        currentMonth]}
                />
                {joblists.length !== 0 &&
                    <>
                        <WorksmanJobListingReviewsComp
                            onopen={handleDrawerOpen}
                            getAllJobsFun={getAllJobsFun}
                            reviews={reviews}
                            allJobsData={ WorksmanJobListingReviewsHelper(jobType, joblists,bookingDate)}
                        />
                    </>
                }
                {joblists.length === 0 && <div>No Data</div>}
            </div>
            <CustomerDetailsComp
                open={open}
                setOpen={setOpen}
                handleDrawerOpen={handleDrawerOpen}
                messages={messages}
                getMessages={getMessages}
                single_user_data={single_user_data}
                handleDrawerClose={handleDrawerClose}
                getAllJobsFun={getAllJobsFun}
            />
        </UserDashboardComp>
    );
}
