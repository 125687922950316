import { Box } from "@mui/system";
import { ToastContainer } from "react-toastify";
import { ScheduleTimingsForm } from "../../forms/ScheduleTimingsForm";

export const ScheduleTimingsComp = ({
    handleClose,
    date,
    storeWorksmanCurrentAvailability,
    submitWorksmanCurrentAvailability,
    worksManCurrentAvailability,
}: any) => {
    return (
        <>
            <div className="drawer_container common-styles">
                <Box sx={{ padding: "10px 20px 30PX 20PX" }} role="presentation">
                    <ScheduleTimingsForm
                        handleClose={handleClose}
                        date={date}
                        storeWorksmanCurrentAvailability={storeWorksmanCurrentAvailability}
                        submitWorksmanCurrentAvailability={submitWorksmanCurrentAvailability}
                        worksManCurrentAvailability={worksManCurrentAvailability}
                    />
                </Box>
            </div>
            <ToastContainer />
            <Box sx={{ display: "flex", overflow: "hidden" }}></Box>
        </>
    );
};
