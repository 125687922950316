import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const UserAccountInfoApi = async (workmanId: any): Promise<any> => {
    try {
        const config = {
            headers: {
                worksman_id: workmanId,
                Authorization: await jwtToken()
            }
        }
        const data = await axios.get(`${envDevUrl}/GetWorkmanDetailsApi`, config)
        return data
    } catch (exception) {
        console.log(exception);
        throw exception;
    }
}