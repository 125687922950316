export enum WebActionTypes {
  set_users = "SET_USERS",
  set_User_id = "SET_USERS_ID",
}

export enum BankDetailsTypes {
  GET_BANK_DETAILS = "GET_BANK_DETAILS",
  EDIT_BANK_DETAILS = "EDIT_BANK_DETAILS"

}

export enum UserActionStatusTypes {
  set_user_status = "SET_USER_STATUS",
}

export enum UserAuthenticationTypes {
  SET_USER_AUTHENTICATION = "SET_USER_AUTHENTICATION",
}

export enum GetUserdetailsTypes {
  GET_USER_DETAILS = "GET_USER_DETAILS",
}


// POST CODE ACTION TYPES
export enum postCodeActionTypes {
  GET_POST_CODE = "GET_POST_CODE"
}


// SCHEDULE ACTION TYPES
export enum scheduleActionTypes {
  GET_SCHEDULE = "GET_SCHEDULE",
  ALL_STATE_ACTION_TYPES = 'ALL_STATE_ACTION_TYPES'
}

// JOB LISTING
export enum jobListingTypes {
  GET_JOB_LISTING = "GET_JOB_LISTING",
  GET_JOBS_BASED_ON_MONTH="GET_JOBS_BASED_ON_MONTH"
}

// JOB LISTING
export enum chatConversationTypes {
  CREATE_MESSAGE = "CREATE_MESSAGE",
  GET_MESSAGE = "GET_MESSAGE",
  DELETE_MESSAGE = "DELETE_MESSAGE",
  UPDATE_MESSAGE = "UPDATE_MESSAGE"
}

// SINGLE USER DATA
export enum singleUserDataTypes {
  GET_SINGLE_USER_DATA = "GET_SINGLE_USER_DATA"
}

// payment history details USER DATA
export enum paymentHistoryDetailsActionType {
  GET_PAYMENT_HISTORY_DETAIL = "GET_PAYMENT_HISTORY_DETAIL"
}


// payment USER DATA
export enum paymentDetailsActionType {
  GET_PAYMENT_DETAIL = "GET_PAYMENT_DETAIL"
}

// payment schedule drawer data
export enum drawerSelectedDataType {
  GET_SCHEDULE_DRAWER_DATA = "GET_SCHEDULE_DRAWER_DATA",
  CREATE_SCHEDULE_DRAWER_DATA = "CREATE_SCHEDULE_DRAWER_DATA",
  DELETE_SCHEDULE_DRAWER_DATA = "DELETE_SCHEDULE_DRAWER_DATA",
}

// payment schedule drawer data
export enum postcodePrefixActionTypes {
  GET_CHECKED_POSTCODES = "GET_CHECKED_POSTCODES",
  CREATE_CHECKED_POSTCODES= "CREATE_CHECKED_POSTCODES",
  DELETE_CHECKED_POSTCODES = "DELETE_CHECKED_POSTCODES",
}


export enum testActionTypes {
  INCREMENT='INCREMENT',
  DECREMENT='DECREMENT'
}