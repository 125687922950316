import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const createWorksmanScheduleApi = async (schedulData: any): Promise<any> => {

    console.log("schedulDataschedulData",schedulData)

    try {
        const config = {
            headers: {
                Authorization: await jwtToken()
            }
        }
        return await axios.post(`${envDevUrl}/CreateWorksmanScheduleApi`, schedulData, config)

    } catch (exception) {
        throw exception
    }
}