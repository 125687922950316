import { chatConversationTypes } from "../constants/action_type"

export const CreateChatConversationAction = (message: any) => {
    return {
        type: chatConversationTypes.CREATE_MESSAGE,
        message: message
    }
}

export const GetChatConversationAction = (message: any) => {
    return {
        type: chatConversationTypes.GET_MESSAGE,
        message: message
    }
}