import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const PaymentsDetailsApi = async (worksman_id:any): Promise<any> => {
    try {
        const config = {
            headers: {
                Authorization: await jwtToken(),
                worksman_id:worksman_id
            }
        }
        const data = await axios.get(`${envDevUrl}/GetPaymentDetailsApi`,config)
        return data
    } catch (exception) {
        throw exception
    }
} 