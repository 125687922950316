import { testActionTypes } from "../constants/action_type"

const intialState = {
    count :10
}


export const testReducer = (state=intialState,action) => {
switch(action.type){
    case testActionTypes.INCREMENT:
        state = {
            ...state,
            count:state.count-1
        }
        break;
    case testActionTypes.DECREMENT:
        state = {
            ...state,
            count:state.count-1
        }
        break;
}
return state
}