import * as Redux from "redux";
import { GetUserdetailsTypes } from "../constants/action_type";


export interface GetUserdetailsSuccess extends Redux.Action {
    type: GetUserdetailsTypes.GET_USER_DETAILS;
    userDetails: any;
  }
  
  
  export const GetUserdetailsAction =  (userDetails: any) => {
    return {
      type: GetUserdetailsTypes.GET_USER_DETAILS,
      userDetails: userDetails,
    };
  };