import { combineReducers } from "redux";
import { GetUserDetailsReducer } from "./getUserDetailsReducer";
import { userAuthReducer } from "./userAuthenticationReducer";
import { userPostcodesReducer } from "./userPostcodeReducers";
import { usersReducer } from "./userReducer";
import { userStatusReducer } from "./userStatusReducer";
import { bankDetailsReducer } from "./bankDetailsReducer";
import { UserScheduleReducers } from "./UserScheduleReducers";
import { AllActions } from "../actions/allActions";
import { JobListingReducer } from "./JobListingReducer";
import { ChatConversationReducer } from "./ChatConversationReducer";
import { SingleUserDataReducer } from "./SingleUserDataReducer";
import { PaymentsDetailsReducer } from "./PaymentsDetailsReducer";
import { paymentHistoryDetailsReducer } from "./paymentHistoryDetailsReducer";
import { givenPostcodeReducer } from "./givenPostcodeReducer";
import { postcodePrefixReducers } from "./postcodePrefixReducers";
import { MonthWiseJobsReducer } from "./MonthWiseJobsReducer";
import { testReducer } from "./testReducers";

const appReducer = combineReducers({
  allUsers: usersReducer,
  allBanksDetails:bankDetailsReducer,
  UsersStatus: userStatusReducer,
  UserAuth: userAuthReducer,
  userDetails:GetUserDetailsReducer,
  postcodeDetils:userPostcodesReducer,
  scheduleJobs:UserScheduleReducers,
  bookingLists:JobListingReducer,
  // MonthWiseJobs:MonthWiseJobsReducer,
  ChatConversationReducer:ChatConversationReducer,
  singleUserData:SingleUserDataReducer,
  paymentDetails:PaymentsDetailsReducer,
  paymentHistoryDetails:paymentHistoryDetailsReducer,
  scheuleBookedData:givenPostcodeReducer,
  postcodePrefixData:postcodePrefixReducers,
  testreducer:testReducer
});


 const rootReducer = (state: any, action: AllActions) => {
  return appReducer(state, action);
};
export default rootReducer;