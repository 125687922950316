export const envUrl = 'http://localhost:3009'
export const envDevUrl = 'https://6rlb2zfcb7.execute-api.us-east-2.amazonaws.com/dev'
export const getAddressUrl = 'https://api.getaddress.io/find'


export const jobs = ['All Jobs', 'Completed Jobs', 'Today Jobs', 'Up Coming Jobs']

export const scheduleTimings = ["08:00-10:00", "10:00-12:00", "12:00-14:00", "14:00-16:00", "18:00-20:00", "16:00-18:00"]

export const districts = ["Sevenoaks", "Dartford", "Gravesham", "Tonbridge and Malling",
    "Medway", "Maidstone", "Tunbridge Wells", "Swale", "Ashford",
    "Canterbury", "Folkestone and Hythe", "Thanet", "Dover"]
