import flag1 from "./assets/images/flga1.svg";
import flag2 from "./assets/images/flag2.svg";
import flag3 from "./assets/images/flag3.svg";
import flag4 from "./assets/images/flag4.svg";

export const ReviewsData = [
  {
    img: "https://i.pinimg.com/736x/0f/fa/ab/0ffaab92ddeae61e65c6bd7823be774c.jpg",
    content:
      "Vrishkar have given us an mazing opportunity to change our lives for better",
    name: "Kendal",
    flag: flag1,
  },
  {
    img: "https://th.bing.com/th/id/OIP.o7epNRuwO3s6H3NnvrQrUgHaMN?pid=ImgDet&rs=1",
    content: "The team of vrishkar are nothingbut helpful, kind and supportive",
    name: "sagufta",
    flag: flag2,
  },
  {
    img: "https://th.bing.com/th/id/R.6fcaff9cb43f556870e571b92334d2a8?rik=mt3AeETmUMahVw&riu=http%3a%2f%2fimg.xcitefun.net%2fusers%2f2010%2f08%2f212319%2cxcitefun-portrait-3.jpg&ehk=xSaZiPwS229tEEqTChm509TMRRft9MktV30F1h12QRE%3d&risl=&pid=ImgRaw&r=0",
    content:
      "Firendly and absolutely amazing team with high attention to detail",
    name: "Darren",
    flag: flag3,
  },
  {
    img: "https://backend.unikomodels.com/wp-content/uploads/2019/10/BOOK-EMILY-LUCIA-1.jpg",
    content:
      "I have this company to very honest and helpful while delivering a completely proffessional cleaning service",
    name: "Maria",
    flag: flag4,
  },
];
