import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const CreateMessageApi = async (bookingId: any, worksmanId: any, chat_message: any): Promise<any> => {
    try {

        const config = {
            headers: {
                Authorization: await jwtToken()
            }
        }
        const data = {
                booking_id: bookingId,
                Comunication_given_person_id: worksmanId,
                booking_communication_changed_by_text: 'worksman',
                notes: chat_message,
        }

        return await axios.post(`${envDevUrl}/CreateCommunicationDataApi`, data, config)
    } catch (exception) {
        console.log(exception);
        throw exception;
    }
}