// UI COMPONENTS
import { Grid, Typography } from "@mui/material";

// assets
import gardian from "../../assets/images/gradian-image.svg";
import timesImg from "../../assets/images/times-img.svg";
import bbcNewsImg from "../../assets/images/BBC-img.svg";

export const InNewsComp = () => {
  return (
    <div>
      <Grid
        className=" common-padding"
        container
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={3} sm={3} className="img">
          <Typography style={{ fontSize: 25, fontWeight: "bold" }}>
            In the news!
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3} className="logo-image-container">
          <img src={gardian} className="logo-image" alt="" />
        </Grid>
        <Grid item sm={3} xs={3} className="logo-image-container">
          <img src={timesImg} className="logo-image" alt="" />
        </Grid>
        <Grid item xs={3} sm={3} className="logo-image-container">
          <img src={bbcNewsImg} className="logo-image" alt="" />
        </Grid>
      </Grid>
    </div>
  );
};
