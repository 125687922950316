import { Card, TextField, Button, IconButton, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export const ChangeEmailForm = ({ ChangeEmail, email, onBlur ,emailUpdate,handleChange,otpBox}: any) => {

    const history = useHistory();

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
    });

    return (
        <form onSubmit={handleSubmit(ChangeEmail)}>
            <div className="commom_back_icon" onClick={() => history.goBack()}>
                <IconButton className="icon_button">
                    <ArrowBackIosIcon className="icon_button__arrow-icon" />
                    Back
                </IconButton>
            </div>
            <Card className="commom_card">
                <h2 className="email_page__heading">Change Email</h2>
                <Stack spacing={3}>
                    <div className='common_info_container'>
                        <div className=" common_info_container__content">{email}</div>
                        <div><KeyboardArrowDownIcon /></div>
                    </div>
                    <TextField
                        fullWidth
                        label="New Email"
                        className="text-field"
                        value={emailUpdate.newEmail}
                        {...register("newEmail", { required: true })}
                        name={'newEmail'}
                        onBlur={onBlur}
                        onChange={handleChange}
                    />
                    {errors.email && (
                        <span className="errors">This field is required</span>
                    )}
                    <TextField
                        fullWidth
                        label="Re-enter Email"
                        className="text-field"
                        value={emailUpdate.reEnterEmail}
                        {...register("reEnterEmail", { required: true })}
                        name={'reEnterEmail'}
                        onBlur={onBlur}
                        onChange={handleChange}
                    />


                    {errors.re_entet_email && (
                        <span className="errors">This field is required</span>
                    )}
                    {otpBox &&
                        <TextField
                            fullWidth
                            label="OTP"
                            className="text-field"
                            {...register("Otp", { required: true })}
                        />
                    }
                    {errors.Otp && (
                        <span className="errors">This field is required</span>
                    )}

                    <div className="button">
                        <Button variant="contained" type="submit" size="large" 
                        className="common_button" >
                            Submit
                        </Button>
                    </div>
                </Stack>
            </Card>
        </form>
    )
}