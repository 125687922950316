import moment from "moment"
import ClockIcon from "../../assets/images/ClockIcon.svg";
import persoIcon from "../../assets/images/persoIcon.svg";
import addressIcon from "../../assets/images/addressIcon.svg";
import phone from "../../assets/images/phone.svg";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LoaderComp } from "../CommonComponents/LoaderComp";
import { CustomerDetailsComp } from "../ListOfJobsComponents/CustomerDetailsComp";

export const DashboardCurrentJobsComp = ({handleDrawerOpen,joblists,open,setOpen

}) => {
    console.log("joblistsjoblists",joblists)
    return (
        <div>
            <h2 className="heading">Today's Job</h2>
            <div className="content">
                <div className="sub_content">
                    {joblists.length === 0 && '☹️ Today No Jobs available'}
                    {joblists.length !== 0 &&
                        joblists.filter((e: any) => moment().isAfter(e.booking_slot_date)).map((todayJobs: any, indx: any) => {
                            return (
                                <div className="todays_job_content" key={indx}>
                                    <div className="sechdule">
                                        <div>
                                            <img className="clock" src={ClockIcon} />
                                            <p className="timing">{todayJobs.booking_slot_time}</p>

                                            <div
                                                className={`vertical selected_vertical`}
                                            >
                                                <div
                                                    className={`top_circle active_top_circle`}
                                                ></div>
                                                <div
                                                    className={`bottom_circle active_bottom_circle`}
                                                ></div>
                                                <div className="top_time">{todayJobs.booking_slot_time.split('-')[0]}</div>
                                                <div className="bottom_time">{todayJobs.booking_slot_time.split('-')[1]}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card_content">
                                        <div className="candidate">
                                            <img className="candidate_icon" src={persoIcon} />
                                            <div className="candidate_information">
                                                {todayJobs.first_name} {todayJobs.last_name} 
                                            </div>
                                        </div>
                                        <div className="candidate">
                                            <img className="candidate_icon" src={addressIcon} />
                                            <div className="candidate_information">
                                                {todayJobs.post_code} {todayJobs.county} {todayJobs.street}
                                            </div>
                                        </div>
                                        <div className="candidate">
                                            <img className="candidate_icon" src={phone} />
                                            <div className="candidate_information">
                                                {todayJobs.phone_number}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card_arrow">
                                        <IconButton onClick={() => handleDrawerOpen(todayJobs)}  >
                                            <ArrowForwardIosIcon className={`job_listing__right_arrow`} />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })}
                    <CustomerDetailsComp
                        open={open}
                        setOpen={setOpen}
                        handleDrawerOpen={handleDrawerOpen}
                    />
                </div>
            </div>
        </div >
    )
}