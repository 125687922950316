import { UserAuthenticationAction } from "../actions/allActions"
import { UserAuthenticationTypes } from "../constants/action_type";

const initialAuthStatus = {
    auth: {}
}

export const userAuthReducer = (state = initialAuthStatus, action: UserAuthenticationAction) => {
    switch (action.type) {
        case UserAuthenticationTypes.SET_USER_AUTHENTICATION:
            return {...action.auth};
        default: return state
    }
}