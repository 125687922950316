import moneyIcon from "../assets/images/money.svg";
import timeSymbol from "../assets/images/time-symbol.svg";
import callIcon from "../assets/images/call-icon.svg";
import location from "../assets/images/location.svg";
import jobIcon from "../assets/images/instant-job-icon.svg";
import identicalJobIcon from "../assets/images/identical-job-icon.svg";
import paymentIcon from "../assets/images/payment-icon.svg";
import trackJob from "../assets/images/track-job.svg";

export const startCleanerOne = [
  {
    img: moneyIcon,
    text: "Earn £200 to £800 per week",
  },
  {
    img: timeSymbol,
    text: "Flexible Work hours",
  },
  {
    img: callIcon,
    text: "Helpful cleaner support team",
  },
  {
    img: location,
    text: "Work in your area",
  },
  {
    img: jobIcon,
    text: "Instant availbuility of jobs",
  },
];

export const startCleanerTwo = [
  {
    img: identicalJobIcon,
    text: "Choose Identical Jobs",
  },
  {
    img: paymentIcon,
    text: "Safe and Secure Payments",
  },
  {
    img: trackJob,
    text: "Easy and secure portal to keep track of your jobs",
  },
];
