import { FormGroup, Grid, Stack } from "@mui/material";
import { CheckBoxComp } from "../CommonComponents/CheckBoxComp";

export const EligibilityToWorkComp = ({register, errors}: any) => {
  return (
    <div className="experience_content">
      <div className="experience_content_elements">
        <div className="experience_content_text">
          <h2 className="">Right to Work</h2>
          <p
            className="experience_small_text"
          >
            Please Confirm your eligibility of working in the United Kingdom.
          </p>
          <p className="experience-container__eligiblity">Eligibility:</p>
          <div>
            <FormGroup className="checkbox_container" >
              <CheckBoxComp
                label="I have right to work in Uk"
                register={register}
                name={"right_to_work_uk"}
                require={true}
              />
              <CheckBoxComp
                label="I'dont have any criminal records"
                register={register}
                name={"no_criminal_records"}
                require={true}
              />
              <CheckBoxComp
                label="I have bank account"
                register={register}
                name={"have_bank_account"}
                require={true}
              />
              {(errors.right_to_work_uk ||
                    errors.have_bank_account ||
                    errors.no_criminal_records) && (
                      <span className="errors">
                        Please check all boxes to confirm your eligibility
                      </span>
                    )}
            </FormGroup>
          </div>
        </div>
      </div>
    </div>
  );
};
