import { jobListingTypes } from "../constants/action_type"



export const GetJobListingAction = (listofjobs: any) => {
    return {
        type: jobListingTypes.GET_JOB_LISTING,
        payload: listofjobs
    };
};

