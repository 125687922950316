import axios from 'axios'
import { envDevUrl } from '../constants/allConstants'
import { jwtToken } from '../constants/jwtToken'

export const UpdateStatusApi = async (
  worksmanId: any,
  bookingId: any
): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: await jwtToken(),
      },
    }

    const data = {
      booking_status_changed_by_text: 'worksman',
      booking_status_changed_by_person_id: worksmanId,
      booking_status: 'accept',
      booking_id: bookingId,
    }
    console.log('cxcvcvvcxvcxvcxvxcvcxvxcvxdf',worksmanId)

    return await axios.put(`${envDevUrl}/UpdateBookingStatusApi`, data, config)
  } catch (exception) {
    console.log(exception)
  }
}
