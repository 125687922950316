import { postcodePrefixActionTypes } from "../constants/action_type"

export const createPostcodePrefixAction = (data: any) => {
    return {
        type: postcodePrefixActionTypes.CREATE_CHECKED_POSTCODES,
        payload: data
    }
}


export const getPostcodePrefixAction = (data: any) => {
    return {
        type: postcodePrefixActionTypes.GET_CHECKED_POSTCODES,
        payload: data
    }
}


export const deletePostcodePrefixAction = (data: any) => {
    return {
        type: postcodePrefixActionTypes.DELETE_CHECKED_POSTCODES,
        payload: data
    }
}