import { Route, Switch } from "react-router-dom"
import { PersonalInformationPage } from "./pages/PersonalInformationPage";
import {
    bank_details, update_email, payments, personal_information, post_codes, userProfile,
    update_phonenumber, update_password, manage_address, terms_and_condition, tersmsAndCondtions,
    UserExperience, success, userScheduleJob, user_dashobard, job_listing, payments_details,
    edit_bank_details
} from "./routersNames"

import {
    TermsAndConditionPage, ExperiencePage, SuccessPage, UserDashboardComp, AccountInfoPage, ScheduleJobPage,
    PostCodesPage, UserDashboardBankDetailsPage, PaymentsPage, ChangeEmailPage, PhoneNumberPage,
    TermsConditionPage, ManageAddress, ChangePasswordPage, EidtBankDetailsComp
} from './basmodule'
import { ListOfJobsPage } from "./pages/ListOfJobsPage";
import { PaymentsDetailsComp } from "./components/PaymentsComponents/PaymentsDetailsComp";

export const PrivateRoutes = () => {
    return (
        <Switch>
            <Route exact path={tersmsAndCondtions} component={TermsAndConditionPage} />
            <Route exact path={UserExperience} component={ExperiencePage} />
            <Route exact path={success} component={SuccessPage} />

            <Route exact path={user_dashobard} component={UserDashboardComp} />
            <Route exact path={userProfile} component={AccountInfoPage} />
            <Route exact path={userScheduleJob} component={ScheduleJobPage} />
            <Route exact path={post_codes} component={PostCodesPage} />
            <Route exact path={bank_details} component={UserDashboardBankDetailsPage} />
            <Route exact path={edit_bank_details} component={EidtBankDetailsComp} />
            <Route exact path={payments} component={PaymentsPage} />
            <Route exact path={payments_details} component={PaymentsDetailsComp} />
            <Route exact path={job_listing} component={ListOfJobsPage} />


            {/* Sub component of user Profile */}
            <Route exact path={personal_information} component={PersonalInformationPage} />
            <Route exact path={update_email} component={ChangeEmailPage} />
            <Route exact path={update_phonenumber} component={PhoneNumberPage} />
            <Route exact path={update_password} component={ChangePasswordPage} />
            <Route exact path={manage_address} component={ManageAddress} />
            <Route exact path={terms_and_condition} component={TermsConditionPage} />
        </Switch>
    )
}
