import { Card, CardContent } from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { UserStatusActions } from "../actions/UserStatusActions";
import { UserCurrentStatusApi } from "../api/UserCurrentStatusApi";
import { SignInForm } from "../forms/SignInForm";
import { RedirectionHelper } from "../helper/SignInPageHelpers";

export const SigninPage = () => {

  // constants
  const dispatch = useDispatch()
  const { status } = useSelector((state: any) => state.UsersStatus)
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

  //states
  const [passwordText, setpasswordText] = useState("")
  const [btn, setBtn] = useState(false)

  // Login oage
  const LoginUser = async (data: any) => {
    const { emailId, password } = data;
    try {
      setBtn(true)
      const userLogin = await Auth.signIn(emailId, password);
      const currentStep = await UserCurrentStatusApi(emailId);
      toast.success("signin Successfully")
      dispatch(UserStatusActions(JSON.parse(currentStep.data.data).body))

      if (userLogin) {
        const { current_step, is_user_active_status } = JSON.parse(
          currentStep.data.data
        ).body[0];
        RedirectionHelper(current_step, is_user_active_status);
      }
    } catch (exception) {
      toast.error(`${exception}`)
      console.log(exception);
      setBtn(false)
      throw exception;
    }
  };


  return (
    <>
      <ToastContainer />
      <div className="loging_page common-styles">
        <Card className="commom_card">
          <CardContent>
            <SignInForm
              LoginUser={LoginUser}
              passwordText={passwordText}
              setpasswordText={setpasswordText}
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              status={status}
              btn={btn}
            />
          </CardContent>
        </Card>
      </div>
    </>
  );
}
