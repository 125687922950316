import { Modal } from 'react-bootstrap';

export const ModalBoxComp = ({ show, onClose, handleClose, title, ...children }: any) => {

    return (
        <Modal show={show}  onHide={onClose} backdrop="static" keyboard={false} centered >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body {...children}>
            </Modal.Body>
        </Modal>
    );
}

