import React, { useEffect } from "react";
import { Card, Grid, IconButton, Divider } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";

export const PersonalInformationPage = () => {
  const history = useHistory();
  let { state }: any = history.location
  const { first_name, last_name, nationality, date_of_birth, gender, email, phone } = state[0]

  return (
    <div className="personal-information common-styles ">
      <div className="sub_container">
        <div className="backIcon" onClick={() => history.goBack()}>
          <IconButton className="IconButton">
            <ArrowBackIosIcon className="arrowIcon" />
            Back
          </IconButton>
        </div>
        <Card className="card">
          <div className="card_content">
            <h1 className="heading_personal_information">Personal Information</h1>
            <Divider className="top_divider" />
            <div className="personal-information__container">
              <div className="personal-information__container__content ">
                <p className="personal-information__container__content__heading">Name </p>
                <p className="personal-information__container__content__details">
                  {first_name} {last_name}
                </p>
              </div>
              <Divider className="divider" />

              <div className="personal-information__container__content">
                <p className="personal-information__container__content__heading">Nationality</p>
                <p className="personal-information__container__content__details">
                  {nationality}
                </p>
              </div>
              <Divider className="divider" />
              <div className="personal-information__container__content">
                <p className="personal-information__container__content__heading">Date Of Birth</p>
                <p className="personal-information__container__content__details">
                  {date_of_birth}
                </p>
              </div>
              <Divider className="divider" />
              <div className="personal-information__container__content">
                <p className="personal-information__container__content__heading">Gender</p>
                <p className="personal-information__container__content__details">
                  {gender}
                </p>
              </div>
              <Divider className="divider" />
              <div className="personal-information__container__content">
                <p className="personal-information__container__content__heading">Email</p>
                <p className="personal-information__container__content__details">
                  {email}
                </p>
              </div>
              <Divider className="divider" />
              <div className="personal-information__container__content">
                <p className="personal-information__container__content__heading">Phone Number</p>
                <p className="personal-information__container__content__details">
                  {phone}
                </p>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
