import { FormControlLabel, Checkbox } from "@mui/material";


export const CheckBoxComp = ({ label, register, name, require }: any) => {
  return (
    <>
      <FormControlLabel
        className="common_checkbox"
        control={
          <Checkbox className="common_checkbox_content"
            color="success" 
            {...register(name, { required: require })}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 ,color:"black"} }}
          />}
        label={label}
      />
    </>
  );
};