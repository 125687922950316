import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const createPostcodeApi = async (postcodePrefixs: any): Promise<any> => {

    const config = {
        headers: {
            Authorization: await jwtToken()
        }
    }
    try {
        return await axios.post(`${envDevUrl}/createPostcodeApi`, postcodePrefixs, config)
    } catch (exception) {
        console.log(exception)
        throw exception
    }
}