// MUI components
import { Card, TextField, Button, IconButton, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";


export const BankDetailForm = ({ updateBankDetails, bankDetail }: any) => {



    const history = useHistory()
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    return (
        <form onSubmit={handleSubmit(updateBankDetails)}>
            <div className="backIcon" onClick={() => history.goBack()}>
                <IconButton className="IconButton">
                    <ArrowBackIosIcon className="arrowIcon" />
                    Back
                </IconButton>
            </div>
            <Card className="card">
                <h1 className="bankDetailsSave_heading">Bank Details</h1>
                <div className="textField">
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            label="Name"
                            className="text-field"
                            defaultValue={bankDetail.account_name}
                            {...register("name", { required: true })}
                        />
                        {errors.name && (
                            <span className="errors">This field is required</span>
                        )}
                        <TextField
                            fullWidth
                            label="Sort code"
                            className="text-field"
                            defaultValue={bankDetail.sort_code}
                            {...register("sortCode", { required: true })}
                        />
                        {errors.SortCode && (
                            <span className="errors">This field is required</span>
                        )}

                        <TextField
                            fullWidth
                            label="Account Number"
                            className="text-field"
                            defaultValue={bankDetail.account_number}
                            {...register("accountNumber", { required: true })}

                        />
                        {errors.Account_Number && (
                            <span className="errors">This field is required</span>
                        )}
                        <TextField
                            fullWidth
                            label="Bank Name"
                            className="text-field"
                            defaultValue={bankDetail.bank_name}
                            {...register("bankName", { required: true })}
                        />
                        {errors.Bank_Name && (
                            <span className="errors">This field is required</span>
                        )}
                        <div className="button">
                            <Button variant="contained" type="submit" className="btn">
                                Save
                            </Button>
                        </div>
                    </Stack>
                </div>
            </Card>
        </form>

    )
}