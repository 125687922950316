import { Grid, Stack, TextField } from "@mui/material";
import { errorMsg } from "../../constants/errorMsg";
import { CheckBoxComp } from "../CommonComponents/CheckBoxComp";

export const CleaningServiceComp = ({register, errors, checkboxError}: any) => {
  return (

    <div className="experience_content">
      <div className="experience_content_elements">
        <div className="experience_content_text">
          <h2 className="experience_heading">
            Your Experience
          </h2>
          <p className="experience_small_text" >Please fill out the following with answers about your past experience</p>
          <TextField
            className="experience_text-field"
            size="small"
            fullWidth
            label="1. How many years of experience do you have on cleaning ?"
            {...register("experience", { required: errorMsg })}
          />
          <p className="types_cleaning_service">
            Types of cleaning services you like to do?
          </p>
          <div className="checkbox col-md-12 row" >
            <div className="checkbox_first_section col-md-6 col-xs-12">
              <Stack>
                <CheckBoxComp
                  label="End of tendency"
                  register={register}
                  name={"end_of_tendency"}
                  require={false}
                />
                <CheckBoxComp
                  label="Hotel Cleaning"
                  register={register}
                  name={"hotel_cleaning"}
                  require={false}
                />
                <CheckBoxComp
                  label="Other Cleaning"
                  register={register}
                  name={"other_cleaning"}
                  require={false}
                />
              </Stack>
            </div>
            <div className="checkbox_second_section col-md-6 col-xs-12">
              <Stack >
                <CheckBoxComp
                  label={"House Cleaning"}
                  register={register}
                  name={"house_cleaning"}
                  require={false}
                /> <span className="cleaning_of_houe_types">(Oven, kitchen, Bathroom, Door, Carpet, Deep, Inside window cleanings)</span>
                <CheckBoxComp
                  label="Hospital Cleaning"
                  register={register}
                  name={"hospital_cleaning"}
                  require={false}
                />
                <CheckBoxComp
                  label="Office Cleaning"
                  register={register}
                  name={"office_cleaning"}
                  require={false}
                />
              </Stack>
            </div>
          </div>
          {checkboxError && <p className="errors">Please select at least three checkboxes</p>}
        </div>
      </div>
    </div>

  );
};
