import moment from "moment"

export const dateHelpers = (data: any) => {
    return moment().format(`${data}`)
}

export const subStrDateHelpers = (data: any, number: any, month: any, getDate) => {
    return moment(dateHelpers(`${data}`)).subtract(number, month).format(getDate)
}

export const getDates = (date: any, type: any) => {
    return moment(`${date}`, 'DD-MM-YYYY').format(`${type}`)
}



export const changeDateFormate = (date: any) => {
    return moment(date).format('DD MMM YYYY')
}