import { Card, TextField, Button, IconButton, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { phoneRegex } from "../constants/RegExp";
import { updatePhoneNumberApi } from "../api/updatePhoneNumberApi";

export const PhoneNumberPage = () => {
  const history = useHistory();
  const [emails, setEmail] = useState()
  const [otpBox, setOtpBox] = useState(false);
  const [phone, setPhoneNumber] = useState(false);

  const auth = useSelector((state: any) => state)

 
  const { status } = useSelector((state: any) => state.UsersStatus)

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [newPhoneNumber, reEnterPhoneNumber] = getValues(["phone_Number", "re_enter_phone_Number"])

  const onBlur = async (e: any) => {
    let val = e.target.value;
    try {
      if (val.match(phoneRegex)) {
        if (phone !== val) {
          if (otpBox === false) {
            setEmail(val)
            if (newPhoneNumber === reEnterPhoneNumber) {
              const user = await Auth.currentAuthenticatedUser();
              let d = await Auth.updateUserAttributes(user, { phone_number: val });              
            }
            toast.success('Otp send succesfully you enter Phone Number')
            setOtpBox(true)
          }
        } else {
          toast.error("Please Enter new Phone Number")
        }

      } else {
        toast.error('Please Enter Valid Phone Number pattern')
      }
    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  const PhoneNumberPage = async (data) => {
    try {
      if(status.length!==0){

      }
        const user = await Auth.currentAuthenticatedUser();
        await Auth.verifyUserAttributeSubmit(user, 'phone_number', data.Otp)
        await updatePhoneNumberApi(status[0].worksman,phone)
        toast.success("Phone Number changed successfully")
    } catch (exception) {
      toast.error(`${exception}`)
      console.log(exception);
      throw exception;
    }
  };

  useEffect(() => {
    const phoneData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setPhoneNumber(user.attributes.phone_number)
    }
    phoneData()
  }, [])
  return (
    <>
      <ToastContainer />
      <div className="phonenumber_page common-styles">
        <form onSubmit={handleSubmit(PhoneNumberPage)}>
          <div className="commom_back_icon" onClick={() => history.goBack()}>
            <IconButton className="icon_button">
              <ArrowBackIosIcon className="icon_button__arrow-icon" />
              Back
            </IconButton>
          </div>
          <Card className="card commom_card">
            <h2 className="phonenumber_page__heading">Change Phone Number</h2>
            <Stack spacing={3}>
              <div className='common_info_container'>
                <div className="common_info_container__content">{phone}</div>
                <div><KeyboardArrowDownIcon /></div>
              </div>
              <TextField
                fullWidth
                label="New Phone Number"
                className="text-field"
                {...register("phone_Number", { required: true })}
              />
              {errors.phone_Number && (
                <span className="errors">This field is required</span>
              )}
              <TextField
                fullWidth
                label="Re-Enter Phone Number"
                className="text-field"
                {...register("re_enter_phone_Number", { required: true })}
                onBlur={onBlur}
              />
              {errors.re_enter_phone_Number && (
                <span className="errors">{errors.re_enter_phone_Number.message}</span>
              )}
              <TextField
                fullWidth
                label="OTP"
                className="text-field"
                {...register("Otp", { required: true })}
              />
              {errors.Otp && (
                <span className="errors">This field is required</span>
              )}

              <div className="button">
                <Button variant="contained" type="submit" className="common_button">
                  Save
                </Button>
              </div>
            </Stack>
          </Card>
        </form>
      </div>
    </>
  );
};
