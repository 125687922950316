import {
    Divider,
    Grid,
    IconButton,
    Typography,
    Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { ScheduleTiminigsListComp } from "../components/SchedulejobsComponents/ScheduleTiminigsListComp";
import { scheduleTimings } from "../constants/allConstants";

export const ScheduleTimingsForm = ({
    handleClose,
    date,
    storeWorksmanCurrentAvailability,
    submitWorksmanCurrentAvailability,
    worksManCurrentAvailability,
}: any) => {
    return (
        <form onSubmit={submitWorksmanCurrentAvailability}>
            <Grid container direction={"row"}>
                <Grid item xs={2}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon style={{ marginBottom: 0, fontSize: 20 }} />
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <Typography pt={0.5}>
                        {moment(date).format("ddd,MMM DD")} - Schedule Event
                    </Typography>
                </Grid>
            </Grid>
            <Divider />
            <div className="schedule_container row ">
                {scheduleTimings.map((time: any, index: any) => {
                    const isbooked =
                        worksManCurrentAvailability &&
                        worksManCurrentAvailability.includes(time);

                        // const selectedDate = scheduleData.some((e: any) => {
                        //     return (e.worksman_schedule_time == time) && (changeDateFormate(e.worksman_schedule_date)
                        //         == changeDateFormate(date))
                        // })

                        // const bookedSchedule = scheduleData.some((e: any) => {
                        //     return changeDateFormate(e.worksman_schedule_date) ==
                        //         changeDateFormate(date)
                        //         && e.worksman_schedule_time == time
                        // })
                        // const selectedSchedule = scheduleDrawerData.some((e: any) => {
                        //     return changeDateFormate(e.worksman_schedule_date) ==
                        //         changeDateFormate(date)
                        //         && e.worksman_schedule_time == time
                        // })
                    const cssName = isbooked
                        ? "booked_content"
                        : "drawer_timings";
                    return (

                        <ScheduleTiminigsListComp
                            key={index}
                            index={index}
                            time={time}
                            // cssName={cssName}
                            cssName={isbooked}
                            storeWorksmanCurrentAvailability={
                                storeWorksmanCurrentAvailability
                            }
                        />
                    )
                })}
                <div
                    className="col-md-12"
                    style={{ margin: "20px 10px 0px 1px" }}
                >
                    <Button
                        className="common_button"
                        type="submit"
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </form>
    )
}