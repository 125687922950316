import { Button, Grid, SwipeableDrawer, Dialog } from "@mui/material";
import { UserDashboardComp } from "../components/LayoutComponents/UserDashboardComp";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { GetPostCode } from "../actions/userPostcodeActions";
import { getAllPostcodesApi } from "../api/getAllPostcodesApi";
import { deletePostcodeApi } from "../api/deletePostcodeApi";
import axios from "axios";
import { envDevUrl } from "../constants/allConstants";
import { jwtToken } from "../constants/jwtToken";
import { DistrictWisePostCodesForm } from "../forms/DistrictWisePostCodesForm";
import { createPostcodePrefixAction, deletePostcodePrefixAction } from "../actions/postcodePrefixAction";
import { createPostcodeApi } from "../api/createPostcodeApi";

export const PostCodesPage = () => {

  // states
  const [open, setOpen] = useState(false)
  const [allDistrictPostcode, setDistrictWisePostCodes] = useState([])
  const [dstricts, setDistrict] = useState("Sevenoaks")

  // use selector
  const { status } = useSelector((state: any) => state.UsersStatus)
  const { postcodes } = useSelector((state: any) => state.postcodeDetils)
  const { postcodePrefixs } = useSelector((state: any) => state.postcodePrefixData)

  // use dispatch
  const dispatch = useDispatch()

  // handle click
  const handleClickOpen = () => {
    setOpen(true)
  }

  // handle close
  const handleClose = () => {
    setOpen(false)
  }

  // handle change
  const handleChange = (e: any) => {
    setDistrict(e.target.value)
  }

  // delete postcodes
  const deletePostCodes = async (worksmanId: any, deleteData: any) => {
    try {
      const { data } = await deletePostcodeApi(worksmanId, deleteData)
      getAllPostcodes(status[0].worksman_id)
      toast.success('Postcode deleted succesfully')
    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  // display postcodes
  const displayAllPostCodes = () => {
    if (postcodes.length === 0) {
      return <Grid item sm={12} xs={12} className="add_content" >
        <p className="no_data">☹️ No postcodes available. add more postcode you will get more jobs</p>
      </Grid>

    } if (postcodes.length !== 0) {
      return postcodes.map((e: any, indx: any) => {
        return (
          <Grid item sm={6} xs={12} key={indx} className="add_content">
            <div className="user_dashboard_post_code">
              {e.postcodes}
              <span onClick={() => deletePostCodes(e.worksman_id, e)}>
                <ClearIcon className="delete_post_code" />
              </span>
            </div>
          </Grid>
        );
      })
    }
  }

  // create postcode
  const createPostCodesSubmit = async (e: any) => {
    e.preventDefault()
    try {

      if (postcodePrefixs.length === 0) {
        toast.error('Please select post code to submit')
      } else {
        const { data } = await createPostcodeApi(postcodePrefixs)
        getAllPostcodes(status[0].worksman_id)
        handleClose()
        return postcodePrefixs.length = 0

      }

    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  const selectPostcodeFun = (event: any, selected_postcode_data: any) => {
    const postcodedata = postcodes.some((e: any) => {
      return e.postcodes === selected_postcode_data.postcodes
    })
    // postcodes
    const postdata = postcodePrefixs.some((prefixPostcode: any) => {
      return prefixPostcode.postcodes === selected_postcode_data.postcode
    })

    if (postdata === false && postcodedata === false) {
      dispatch(createPostcodePrefixAction({
        worksman_id: status[0].worksman_id,
        postcodes: selected_postcode_data.postcodes,
        district: selected_postcode_data.district
      }))

    } else if (postdata === true) {

      dispatch(deletePostcodePrefixAction({
        worksman_id: status[0].worksman_id,
        postcodes: selected_postcode_data.postcodes,
        district: selected_postcode_data.district
      }))
    } else if (postcodedata === true) {
      deletePostCodes(status[0].worksman_id, selected_postcode_data)
    }
  }

  // get all post codes
  const getAllPostcodes = async (workmanId: any) => {
    try {
      let getAlldata = await getAllPostcodesApi(workmanId)
      const { body } = JSON.parse(getAlldata.data.data)
      dispatch(GetPostCode(body))

    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  useEffect(() => {
    if (status.length !== 0) {
      getAllPostcodes(status[0].worksman_id)
    }
  }, [status])


  const districtWisePostCodes = async () => {
    try {
      const config = {
        headers: {
          Authorization: await jwtToken()
        }
      }
      const { data } = await axios.get(`${envDevUrl}/GetDistrictWisePostCodesApi`, config)

      setDistrictWisePostCodes(JSON.parse(data.data).body)
      return data

    } catch (exception) {
      throw exception
    }
  }
  useEffect(() => {
    districtWisePostCodes()
  }, [])

  return (
    <UserDashboardComp>
      <ToastContainer />
      <div className="userdashboar_content">
        <h1 className="available_post_code">Available post code</h1>
        <div className="user_postcode_container">
          <div  >
            <Grid item xs={12}>
              <p>
                Local post code which suits for your to work.(You can add more/
                delete at any time)
              </p>
              <p className="imp-note">
                Imp: More postcodes you cover you will get more jobs
              </p>
            </Grid>
            <Grid container spacing={2} justifyContent='space-around' mb={2} mt={2} >
              <Button variant="contained" color="info" onClick={handleClickOpen} className='add_post_code'
                endIcon={
                  <AddCircleOutlinedIcon className="icon_in_md_screen" />
                }
              >
                Add post codes
              </Button>
            </Grid>

            <SwipeableDrawer
              anchor={'right'}
              open={open}
              onClose={handleClose}
              onOpen={handleClickOpen}
              className='desktop_drawer'
              style={{ width: 350 }}
            >
              <DistrictWisePostCodesForm
                deletePostCodes={deletePostCodes}
                allDistrictPostcode={allDistrictPostcode}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                getAllPostcodes={getAllPostcodes}
                DistrictWisePostCodeForm={[
                  createPostCodesSubmit,
                  dstricts,
                  handleChange,
                  selectPostcodeFun,
                  postcodes
                ]}
              />
            </SwipeableDrawer>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              className='mobile_drawer'>
              <DistrictWisePostCodesForm
                deletePostCodes={deletePostCodes}
                allDistrictPostcode={allDistrictPostcode}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                getAllPostcodes={getAllPostcodes}
                DistrictWisePostCodeForm={[
                  createPostCodesSubmit,
                  dstricts,
                  handleChange,
                  selectPostcodeFun,
                  postcodes
                ]}
              />
            </Dialog>
            <Grid item xs={12} container spacing={3} className="post_codes">
              {displayAllPostCodes()}
            </Grid>
          </div>
        </div>
      </div>
    </UserDashboardComp>
  );
};