import { jobListingTypes } from "../constants/action_type"


const initialState = {
    joblists: []
}

export const JobListingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case jobListingTypes.GET_JOB_LISTING:
            state = {
                ...state,
                joblists: action.payload
            }
            break;
    }
    return state
}

