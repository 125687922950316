
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";

export const ScheduleTiminigsListComp = ({
  index,
  time,
  cssName,
  storeWorksmanCurrentAvailability

}: any) => {
  return (
    <div
    className={`drawer_timings `}
    key={index}
    onClick={() =>
      storeWorksmanCurrentAvailability(time, "selectedDate")
    }
  >
      <div
        className={`time ${cssName?'booked_shedule':'not_booked_schedule'} `}
      >
        <div
          className={`line ${cssName?'booked_line':'not_booked_line'} `}
        >
          <div
            className={`top_circle ${cssName?'booked_top_circle':'not_booked_top_circle'} `}
          ></div>
          <div
            className={`bottom_circle ${cssName ?'booked_bottom_circle':'not_booked_bottom_circle'} `}
          ></div>
          <div
            className={`top_time ${cssName ?'booked_top_time':'not_booked_top_time'} `}
          >
            {time.split("-")[0]}
          </div>
          <div
            className={`bottom_time ${cssName?'booked_bottom_time':'not_booked_bottom_time'} `}
          >
            {time.split("-")[1]}
          </div>
        </div>
      </div>

      <div className="booked">
        <div>
          { 
          cssName
           ? (
            <span>
              {" "}
              Booked{" "} 
              <CheckCircleSharpIcon className="checked_icon common_icon" />
            </span>
          ) : (
            <span>
               Add{" "}
              <AddCircleOutlineSharpIcon className="checked_icon common_icon" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}