import { Avatar, Button, Grid, Rating, Typography } from "@mui/material";
import { UpdateStatusApi } from "../../api/UpdateStatusApi";
import { JobDetailsListDividerComp } from "./JobDetailsListDividerComp";

export const JobDetailsCustomerInfoComp = ({ user,getAllJobsFun }: any) => {
    return (
        <>

            <Grid container justifyContent={'center'} className="drawer_container__avatar">
                <Avatar sx={{ width: 56, height: 56 }} style={{ backgroundColor: '#E46F2C' }}  >
                    {user.first_name.toUpperCase().charAt(0)}
                </Avatar>
            </Grid>
            <div className="drawer_container__user-name">
                <Typography style={{ textAlign: 'center', fontWeight: 'bold' }} variant='h6' component='h1' >
                    {user.first_name}  {user.last_name}
                </Typography>
            </div>

            <div >
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>User Address</div>

                            <div>{user.house_number},{user.street},{user.county},{user.city},{user.post_code}</div>

                        </>
                    }

                    divider={true}
                />
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>Service</div>
                            <div>
                                <p>{user.booking_title}</p>
                                {console.log("additional_tasksadditional_tasks", user.additional_tasks)}
                                <ul>
                                    {user.additional_tasks[0].split(",").map((services: any, indx: any) => <li key={indx}>{services}</li>)}
                                </ul>
                            </div>
                        </>
                    }
                    divider={true}
                />
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>No of Hrs</div>
                            <div> {user.no_of_hours} Hrs </div>
                        </>
                    }
                    divider={true}
                />
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>Payment Status</div>
                            <div> Not Done </div>
                        </>
                    }
                    divider={true}
                />
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>Status</div>

                            <div> {user.booking_status} </div>
                        </>
                    }
                    divider={true}
                />
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>Ratings</div>
                            <div>
                                <Rating name="half-rating-read" readOnly defaultValue={user.user_ratings} precision={0.5} />
                            </div>
                        </>
                    }
                    divider={true}
                />
                <JobDetailsListDividerComp
                    children={
                        <>
                            <div>
                                <Button color="warning" variant="contained" size="large"
                                    // disabled={user.booking_status.trim() === 'cancel' ? true : false}
                                    onClick={async () => {
                                        try {
                                            const d = await UpdateStatusApi(user.worksman_id, user.booking_id)
                                            console.log("This is UpdateStatusApi", d)
                                            getAllJobsFun(user.worksman_id, user.booking_slot_date)
                                        } catch (exception) {
                                            console.log(exception)
                                        }
                                    }}
                                >cancel a Job</Button>
                            </div>
                        </>
                    }
                />
            </div>
        </>
    )
}