import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const AllJobsApi = async(worksman_id:any):Promise<any> => {

    try {
        const config = {
            headers: {
                worksman_id: worksman_id,
                Authorization: await jwtToken()
            }
        }
        const data = await axios.get(`${envDevUrl}/GetBookingTableApi`,config)
        return data
    } catch (exception) {
        console.log(exception);
        throw exception;
    }
    
}