// Constants
import { errorMsg } from "../constants/errorMsg";
import { datePattern, emailRegex } from "../constants/RegExp";

// UI components
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {
  Autocomplete,
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import allNations from "../nationality.json"
import { PasswordTextFieldComp } from "../components/CommonComponents/PasswordTextFieldComp";
import { Card } from "react-bootstrap";

// Main Function
export const UserRegistrationForm = ({
  emailError,
  emailSuccess,
  addressApiError,
  showBox,
  error,
  newAddress,
  newAdd,
  setNewAdd,
  setAddSelect,
  setHouseNum,
  setPostCode,
  onSubmit,
  Address,
  onChangeHandler,
  register,
  handleSubmit,
  errors,
  houseNum,
  postcodes,
  setAddStat,
  apiAddresData,
  boxs,
  addSelect,
  setBoxs,
  singleAddCard,
  apiPostCode,
  setShowBox,
  setSingleAddCard,
  setApiAddresData,
  multiAdd,
  setMultiAdd
}: any) => {
  return (
    <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          label="First name"
          id="firstName"
          autoComplete="off"
          className="text-field"
          {...register("firstName", { required: errorMsg })}
        />
        {errors.firstName && (
          <span className="errors">{errors.firstName.message}</span>
        )}

        <TextField
          fullWidth
          label="Last name"
          id="lastName"
          className="text-field"
          {...register("lastName", { required: errorMsg })}
        />
        {errors.lastName && (
          <span className="errors">{errors.lastName.message}</span>
        )}
        <div className="mail">
          <TextField
            fullWidth
            label="Mail Address"
            id="email"
            className="text-field"
            {...register("email", {
              required: errorMsg,
              pattern: {
                value: emailRegex,
                message: "Enter valid Email Id",
              },
            })}
            onChange={onChangeHandler}
          />
          <div className="icons">
            {emailError ? <ErrorOutline color="error" /> : ""}
            {emailSuccess ? (
              " "
            ) : (
              <CheckCircleOutlineOutlined color="success" />
            )}
          </div>
        </div>
        {errors.email && <span className="errors">{errors.email.message}</span>}

        <div
          className="postcode input-group mb-3 number_container"
          style={{ display: "flex" }}
        >
          <div className="number_code">+91</div>
          <TextField
            fullWidth
            label="Mobile number"
            autoComplete="off"
            id="number"
            className="text-field phone_number_field"
            type="text"
            {...register("number", { required: errorMsg })}
          />
        </div>
        {errors.number && (
          <span className="errors">{errors.number.message}</span>
        )}

        <PasswordTextFieldComp
          register={register}
          label="Password"
          errormsgCond={errors.password}
          name="password"
          required={errorMsg}
        />
        <PasswordTextFieldComp
          register={register}
          label="Confirm Password"
          errormsgCond={errors.confirmPassword}
          name="confirmPassword"
          required={errorMsg}
        />

        {/* nationality */}
        <Autocomplete
          id="grouped-demo"
          options={allNations}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("nationality", { required: errorMsg })}
              label="Nationality"
              onChange={(e: any) => {
                return null;
              }}
            />
          )}
        />
        {errors.nationality && (
          <span className="errors">{errors.nationality.message}</span>
        )}
        <TextField
          fullWidth
          label="Date of Birth"
          id="dateOfBirth"
          className="text-field"
          {...register("dateOfBirth", {
            required: errorMsg,
            pattern: {
              value: datePattern,
              message: "Enter valid date of Birth formate",
            },
          })}
        />
        {errors.dateOfBirth && (
          <span className="errors">{errors.dateOfBirth.message}</span>
        )}
        {showBox && (
          <>
            <TextField
              fullWidth
              label="House No/Name"
              id="houseNumber"
              className="text-field"
              {...register("houseNumber", { required: errorMsg })}
              value={houseNum}
              onChange={(e: any) => setHouseNum(e.target.value)}
            />
            {errors.houseNumber && (
              <span className="errors">{errors.houseNumber.message}</span>
            )}
          </>
        )}
        {newAdd && (
          <>
            <TextField
              fullWidth
              label="Street"
              className="text-field"
              {...register("street", { required: true })}
            />
            {errors.street && (
              <span className="errors">{errors.street.message}</span>
            )}
            <TextField
              fullWidth
              label="City/Town"
              className="text-field"
              {...register("city_or_town", { required: true })}
            />
            {errors.city_or_town && (
              <span className="errors">{errors.city_or_town.message}</span>
            )}
            <TextField
              fullWidth
              label="Country"
              className="text-field"
              {...register("country", { required: true })}
            />
            {errors.country && (
              <span className="errors">{errors.country.message}</span>
            )}
          </>
        )}

        {showBox && (
          <>
            <div
              className="postcode input-group mb-3"
              style={{ display: "flex" }}
            >
              <TextField
                fullWidth
                label="Postcode"
                id="Postcode"
                className="text-field"
                {...register("postcode", { required: errorMsg })}
                value={postcodes}
                onChange={(e: any) => setPostCode(e.target.value)}
              />
              {!newAdd && (
                <Button
                  variant="outlined"
                  className="address_find_btn"
                  onClick={() => Address()}
                >
                  Find
                </Button>
              )}
            </div>
            {errors.postcode && (
              <span className="errors">{errors.postcode.message}</span>
            )}

            {newAddress && !newAdd && (
              <span className="errors">
                Unfortunately, we are returning too many matches for this
                address. Please refine your search, or{" "}
                <span
                  style={{ fontWeight: "bold" }}
                  onClick={() => {
                    setNewAdd(true);
                    setAddStat(true);
                  }}
                >
                  Enter manually
                </span>
              </span>
            )}

            {addressApiError ? (
              <span className="errors">
                Enter valid postCode or houseNumber
              </span>
            ) : (
              ""
            )}
            {error ? <span className="errors">Please choose Address</span> : ""}
          </>
        )}

        <FormControl fullWidth>
          {apiAddresData.length > 1 && boxs && (
            <>
              <InputLabel id="demo-simple-select-label">Address</InputLabel>
              <Select
                label="Address"
                {...register("address", { required: errorMsg })}
                value={addSelect}
                onChange={(e: any) => {
                  let val = e.target.value.toString();
                  setAddSelect(val.split(","));
                  setBoxs(false);
                }}
              >
                {apiAddresData.map((e: any, indx: any) => {
                  let value = `${e.line_1},${e.building_name},${e.town_or_city},${e.county}, ${apiPostCode}`;
                  return (
                    <MenuItem key={indx} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )}
        </FormControl>

        {errors.address && (
          <span className="errors">{errors.address.message}</span>
        )}

        {apiAddresData.length === 1 && singleAddCard ? (
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={8}>
                  {apiAddresData.map((e: any, indx: any) => {
                    return (
                      <>
                        <div key={indx} style={{ lineHeight: "10px" }}>
                          <p>{e.building_number}</p>
                          <p>{e.building_name}</p>
                          <p>{e.town_or_city}</p>
                          <p>{e.locality}</p>
                          <p>{e.county}</p>
                          <p>{apiPostCode}</p>
                        </div>
                      </>
                    );
                  })}
                </Grid>

                <Grid item xs={4} style={{ textAlign: "right" }}>
                  <Button
                    onClick={() => {
                      setShowBox(true);
                      setSingleAddCard(false);
                      setPostCode("");
                      setHouseNum("");
                      setApiAddresData([]);
                      setAddSelect([]);
                    }}
                  >
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : apiAddresData.length > 1 ? (
          addSelect.length > 0 &&
          multiAdd && (
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item xs={8}>
                    {addSelect.map((e: any, indx) => {
                      return (
                        <>
                          <div key={indx} style={{ lineHeight: "10px" }}>
                            <p>{e}</p>
                          </div>
                        </>
                      );
                    })}
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        setShowBox(true);
                        setMultiAdd(false);
                        setPostCode("");
                        setHouseNum("");
                        setApiAddresData([]);
                        setAddSelect([]);
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )
        ) : (
          ""
        )}



        <div className="form-gender">
          <FormControl component="fieldset" style={{ display: "inline" }}>
            <Grid
              container
              direction={"row"}
              alignContent={"center"}
              justifyContent={"space-between"}
            >
              <Grid className="form-gender-text">Gender :</Grid>
              <Grid>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="male"
                    control={
                      <Radio {...register("gender", { required: errorMsg })} />
                    }
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio {...register("gender", { required: errorMsg })} />
                    }
                    label="Female"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </FormControl>
        </div>
        {errors.gender && (
          <span className="errors">{errors.gender.message}</span>
        )}

        <hr />
        <div className="form-card">
          <div className="form-gender">
            Privacy Policy, Terms &amp; Conditions
          </div>
          <div className="form-gender">
            <Button
              className="UserRegistration-container__create-btn"
              type="submit"

            >
              Create your Account
            </Button>
          </div>
        </div>
      </Stack>
    </form>
  );
};
