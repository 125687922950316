import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Divider } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";

export const UserAccountDividerWithTextComp = ({ text, link }: any) => {

  return (
    <div  className={"user-dashboar-navlink"} onClick={link}>
      <div className="right_side_content">
        <p className="option_text"> {text}</p>
        <div>
          <ArrowForwardIosIcon className="Icons_style" />
        </div>
      </div>
      <div className="right_side_content__divider" />
    </div>
  );
};
