import { Button } from "react-bootstrap"
import { CreateChatConversationAction, GetChatConversationAction } from '../actions/ChatConversationAction';
import { CreateMessageApi } from '../api/CreateMessageApi';
import send_icons from '../assets/images/send_icons.svg'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

export const WorkmanAndCustomerChatForm = ({user,getMessages}) => {

    const { register, handleSubmit,formState:{errors} } = useForm()
    const { status } = useSelector((state: any) => state.UsersStatus);
    const [msg,setMsg] = useState(false)

    const sendMessage = async (data: any, e: any) => {
        try {
            setMsg(true)
            const getData = await CreateMessageApi(user.booking_id,status[0].worksman_id, data.message)
           console.log("getData",getData)
            getMessages(user.booking_id)
            e.target.reset()
            setMsg(false)
            return getData

        } catch (exception) {
            console.log(exception);
            throw exception;
        }
    }
    return (
        <form onSubmit={handleSubmit(sendMessage)}>
            <div className="drawer_container__chatting-content">
                <div />
                <div>
                    <input
                        autoComplete="off"
                        placeholder="Reply..."
                        className="drawer_container__chattings__input"
                        {...register('message',{required:true})}
                    />
                </div>
                <div >
                    <Button disabled={msg} className="drawer_container__chatting-content__send-btn" type="submit" variant="text">
                        <span> Send <img src={send_icons} />
                        </span>
                    </Button>
                </div>
            </div>
            
            {errors.message && <div style={{color:'red',textAlign:'right'}} >empty messages can't be send</div>}
        </form>
    )
}