import React from "react";
import {
  Card,
  Button,
  IconButton,
  Stack,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { PasswordTextFieldComp } from "../components/CommonComponents/PasswordTextFieldComp";
import { errorMsg } from "../constants/errorMsg";

export const ChangePasswordPage = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const ChangePassword = async (data) => {
    try {
      if (data.re_enter_password !== data.new_password) {
        toast.error("New Password and Re-entered password did not matched");
      } else {
        const user = await Auth.currentAuthenticatedUser();
        const changepassword = await Auth.changePassword(
          user,
          data.current_password,
          data.new_password
        );
        toast.success("Password changed successfully");
      }
    } catch (exception) {
      toast.error(`${exception}`);
      throw exception;
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="change_password common-styles">
        <form onSubmit={handleSubmit(ChangePassword)}>
          <div className="commom_back_icon" onClick={() => history.goBack()}>
            <IconButton className="icon_button">
              <ArrowBackIosIcon className="icon_button__arrow-icon" />
              Back
            </IconButton>
          </div>
          <Card className="commom_card">
            <h2 className="heading">Change Password</h2>
            <Stack spacing={3}>
              <PasswordTextFieldComp
                register={register}
                label='Current Password'
                errormsgCond={errors.current_password}
                name='current_password'
                required={errorMsg}
              />
              <PasswordTextFieldComp
                register={register}
                label='New Password'
                errormsgCond={errors.current_password}
                name='new_password'
                required={errorMsg}
              />
              <PasswordTextFieldComp
                register={register}
                label='Renter Password'
                errormsgCond={errors.re_enter_password}
                name='re_enter_password'
                required={errorMsg}
              />
              <div className="button">
                <Button variant="contained" type="submit" className="common_button">
                  Save
                </Button>
              </div>
            </Stack>
          </Card>
        </form>
      </div>
    </>
  );
};
