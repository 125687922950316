import axios from "axios";
import { envDevUrl } from "../constants/allConstants";

export const getAllMobileNumbersApi = async (): Promise<any> => {
    try {
        return await axios.get(
            `${envDevUrl}/getAllMobileNumbereApiGateway`
        );
    } catch (exception) {
        throw exception
    }
}