import { success, tersmsAndCondtions, UserExperience, user_dashobard } from "../routersNames";

export const RedirectionHelper = (current_step: any, is_user_active_status: any) => {
  try {
    if (current_step === "worksmanRegistration") {
      window.location.href = tersmsAndCondtions;

    } else if (current_step === "worksman-T&C") {
      window.location.href = UserExperience;

    } else if (current_step === "worksmanexperience") {
      // window.location.href = success
      window.location.href = user_dashobard
    } else {
      // window.location.href = user_dashobard
    }
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
};


