import { Grid, Button, Stack, FormGroup } from "@mui/material";;
import { CheckBoxComp } from "../components/CommonComponents/CheckBoxComp";

export const TermsAndConditionForm = ({ onSubmit, handleSubmit, register, errors }: any) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Stack>
          <div className="terms_and_condition">
            <h1 className="heading-terms_condition">Terms &amp; Conditions</h1>
            <p className="TermsAndCondition-text_">
              Please confirm your eligibility of working in the United Kingdom
            </p>
            <p className="TermsAndCondition-eligibility">Eligibility :</p>

            <FormGroup className="eligibility_text">
              <div className="checkBox">
                <CheckBoxComp
                  label={`I've watched "How Vrishkar works" video`}
                  require={true}
                  register={register}
                  name={"eligibilty"}
                />
                <CheckBoxComp
                  label={`I understand this is a Self-employed role`}
                  require={true}
                  register={register}
                  name={"eligibilty1"}
                />
                <CheckBoxComp
                  label={`I understand that Vrishkar isn't responsibile for any damage at the customers permises`}
                  require={true}
                  register={register}
                  name={"eligibilty2"}
                />
              </div>
              {(errors.eligibilty ||
                errors.eligibilty1 ||
                errors.eligibilty2) && (
                  <span className="errors">
                    Please accept all terms and conidtions
                  </span>
                )}

            </FormGroup>
          </div>
        </Stack>
      </Grid>

      <Grid item className="btn-container">
        <Button
          className="btn-join"
          style={{ marginLeft: -40, paddingTop: 10, paddingBottom: 10 }}
          type="submit"
        >
          Continue
        </Button>
      </Grid>
    </form>
  );
};
