import {  Card, IconButton } from "@mui/material";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { recovre_password } from "../routersNames";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AccountRecoveryForm } from "../forms/AccountRecoveryForm";

export const AccountRecoveryPage = () => {
  const history = useHistory();
 

  const forgotPasswordFun = async (data) => {
    const { email } = data;

    try {
      const recoverPwd = await Auth.forgotPassword(email);
      toast.success("OTP sent you email id");
      if (recoverPwd) {
        history.push({ pathname: recovre_password, state: { email } });
      }
    } catch (exception) {
      toast.error(`${exception}`);
      console.log(exception);
      throw exception;
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="account_recovery common-styles">
        <div>
          <div className="commom_back_icon" onClick={() => history.goBack()}>
            <IconButton className="icon_button">
              <ArrowBackIosIcon className="icon_button__arrow-icon" />
              Back
            </IconButton>
          </div>
          <Card className="commom_card">
            <h2>Account recovery</h2>
            <p className="account_recovery__text">
              This help shows that this account really belongs to you
            </p>

            <p className="account_recovery__text">
              An email with verification code was just sent to
            </p>
            <AccountRecoveryForm forgotPasswordFun={forgotPasswordFun} />
          </Card>
        </div>
      </div>
    </>
  );
};
