import { Grid } from "@mui/material";
import vrishkarLogo from "../../assets/images/vrishkar-logo.svg";

// main function
export const FooterBarComp = () => {
  return (
    <div>
      <div className="footer-container">
        <Grid
          container
          justifyContent={"space-between"}
          style={{ padding: 20 }}
        >
          <Grid className="footer-img">
            <img src={vrishkarLogo} className="footer-img" alt="" />
          </Grid>
          <Grid>
            <div>
              <p>@2021 Vrishkar</p>
            </div>
            <div>
              <div>Privacy policy Terms & conditions</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
