import { UserDashboardComp } from "../components/LayoutComponents/UserDashboardComp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import dollar from "../assets/images/dollar.svg";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { payments_details } from "../routersNames";
import { useSelector } from "react-redux";
import { LoaderComp } from "../components/CommonComponents/LoaderComp";
import moment from "moment";
import { getDates } from "../helper/dateHelpers";


export const PaymentsPage = () => {

  const history = useHistory()

  const { paymentDetails } = useSelector((state: any) => state.paymentDetails);
  return (
    <UserDashboardComp>

      {paymentDetails.length === 0 && <LoaderComp />}

      {paymentDetails.length !== 0 &&
        <div className="payment_container">
          <div className="sub_container">
            <h1 className="heading">Payment Details</h1>
            <table>
              <thead>
                <tr>
                  <th>Pay Date</th>
                  <th>status</th>
                  <th>Period End</th>
                  <th>Net Pay</th>
                  <th>Tip</th>
                  <th>Material Cost</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>

                {paymentDetails.map((data: any, indx) => {
                  return <tr key={indx} >
                    <td>{getDates(data.payment_date, 'DD')} {getDates(data.payment_date, 'MMM')} {getDates(data.payment_date, 'YYYY')}</td>
                    <td>{data.payment_status}</td>

                    <td>
                      {moment(`${data.payment_period_end.split('-')[0]}`, 'DD.MM.YYYY').format('DD')}  {moment(`${data.payment_period_end.split('-')[0]}`, 'DD.MM.YYYY').format('MMM')} - {moment(`${data.payment_period_end.split('-')[1]}`, 'DD.MM.YYYY').format('DD')} {moment(`${data.payment_period_end.split('-')[0]}`, 'DD.MM.YYYY').format('MMM')}
                    </td>
                    <td>${data.payment_netpay}</td>
                    <td>${data.payment_tip}</td>
                    <td>${data.payment_material_cost}</td>
                    <td>${data.payment_total}</td>
                    <td>
                      <IconButton onClick={() => history.push({ pathname: payments_details, state: data })}  >
                        <ArrowForwardIosIcon className={`payment-table-right_arrow`} />
                      </IconButton>
                    </td>
                  </tr>
                })}

              </tbody>
            </table>
          </div>

          <div className="payment_container__content">
            <div className="sub_content">
              {paymentDetails.map((todayJobs: any, indx: any) => {
                return (
                  <div className="payments_data_content" key={indx}>
                    <div className="sechdule">
                      <div>
                        <div className={`vertical selected_vertical`} >
                          <div
                            className={`top_circle active_top_circle`}
                          ></div>
                          <div
                            className={`bottom_circle active_bottom_circle`}
                          ></div>
                          <div className="top_time">
                            {moment(`${todayJobs.payment_period_end.split('-')[0]}`, 'DD.MM.YYYY').format('DD')}
                            {moment(`${todayJobs.payment_period_end.split('-')[0]}`, 'DD.MM.YYYY').format('MMM')}
                            {moment(`${todayJobs.payment_period_end.split('-')[0]}`, 'DD.MM.YYYY').format('YYYY')}
                          </div>
                          <div className="bottom_time">
                            {moment(`${todayJobs.payment_period_end.split('-')[1]}`, 'DD.MM.YYYY').format('DD')}
                            {moment(`${todayJobs.payment_period_end.split('-')[1]}`, 'DD.MM.YYYY').format('MMM')}
                            {moment(`${todayJobs.payment_period_end.split('-')[1]}`, 'DD.MM.YYYY').format('YYYY')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card_content">
                      <div className="candidate">
                        <img className="candidate_icon" src={dollar} /> <span className="amount" >250</span>
                        <div className="candidate_information">
                          (Amount £{todayJobs.payment_netpay}, Material Cost	£{todayJobs.payment_material_cost}, Tip	£{todayJobs.payment_tip} )
                        </div>
                      </div>
                    </div>
                    <div className="card_arrow">
                      <IconButton onClick={() => history.push({ pathname: payments_details, state: todayJobs })}  >
                        <ArrowForwardIosIcon className={`card_arrow__right_arrow`} />
                      </IconButton>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

        </div>
      }
    </UserDashboardComp>
  );
};
