import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import { useState } from 'react';

export const PasswordTextFieldComp = ({
    register,
    label,
    errormsgCond,
    name,
    required,
    pattern
}: any) => {

    const [showPassw, setshowPass] = useState(false)

    const handleClickShowPassword = () => {
        setshowPass(!showPassw);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <div>
            <FormControl fullWidth className='text-field' variant="outlined">
                <InputLabel >{label}</InputLabel>
                <OutlinedInput
                   
                    type={showPassw ? 'text' : 'password'}

                    {...register(name, {
                        required: required,
                        pattern:pattern
                    })}

                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassw ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={label}
                />
            </FormControl>
            {errormsgCond && (
                <div className='error_container'>
                    <span className="errors">{errormsgCond.message}</span>
                </div>
            )}
        </div>
    )
}