import { UserActionStatusTypes } from "../constants/action_type";

export const initialStatus = {
  status: [],
};

export const userStatusReducer = (state = initialStatus,action) => {
  switch(action.type){
    case UserActionStatusTypes.set_user_status:
      state={
        ...state,
        status:action.pyaload
      }
  }
  return state
}