import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const deletePostcodeApi = async (worksmanId: any, data: any): Promise<any> => {
  try {
    const config = {
      headers: {
        worksman_id: worksmanId,
        postcodes: data.postcodes,
        district: data.district,
        Authorization: await jwtToken()
      }
    }
    return await axios.delete(`${envDevUrl}/DeletePostcodesApi`, config)
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
}