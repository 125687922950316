import { Auth } from "aws-amplify";

export const userRegistrationHelpers = async (
  apiAddresData: any,
  addSelect: any,
  data: any,
  history: any,
  apiPostCode: any
): Promise<any> => {

  try {
    const houseAdress: any = apiAddresData[0];
    const flatAddress = addSelect.length !== 0 && `${addSelect[0]},${addSelect[1]},${addSelect[2]},${addSelect[3]},${addSelect[4]},${addSelect[5]}`;
    const singleAddress = apiAddresData.length !== 0 && `${houseAdress.building_number}, ${houseAdress.building_name}, ${houseAdress.town_or_city}, ${houseAdress.locality},${houseAdress.county},${apiPostCode}`;
    const manualAddCondition = data.street !== "" && data.country !== "" && data.city_or_town !== ""
    const manulAddress = data.street !== undefined && `${data.houseNumber}, ,${data.street},${data.city_or_town},${data.country},${data.postcode}`

    const signUpData = {
      username: data.email,
      password: data.confirmPassword,
      attributes: {
        given_name: data.firstName,
        family_name: data.lastName,
        gender: data.gender,
        birthdate: data.dateOfBirth,
        phone_number: `+91${data.number}`,
        locale: data.nationality,
        address: `${apiAddresData.length === 1 ? singleAddress :
          addSelect.length !== 0 ? flatAddress :
            manualAddCondition ? manulAddress : ``}`
      }
    }
    // 31,sm26lj
    const register = await Auth.signUp(signUpData)
    if (register) {
      history.push({ pathname: "/confirmationPage", state: data });
    }
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
}
