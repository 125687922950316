import { postcodePrefixActionTypes } from "../constants/action_type"

const initialState = {
    postcodePrefixs: []
}

export const postcodePrefixReducers = (state = initialState, action) => {
    switch (action.type) {
        case postcodePrefixActionTypes.CREATE_CHECKED_POSTCODES:
            state = {
                ...state,
                postcodePrefixs: [...state.postcodePrefixs, action.payload] as any
            }
            break;
        case postcodePrefixActionTypes.GET_CHECKED_POSTCODES:
            state = {
                ...state,
                postcodePrefixs: action.payload
            }
            break;
        case postcodePrefixActionTypes.DELETE_CHECKED_POSTCODES:
            state = {
                ...state,
                postcodePrefixs: state.postcodePrefixs.filter((user: any) =>
                    (user.worksman_id != action.payload.worksman_id || user.postcodes != action.payload.postcodes || user.district != action.payload.district))
            }
            break;
    }
    return state
}