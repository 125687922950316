
import Box from "@mui/material/Box";
import { Dialog, Slide, SwipeableDrawer } from "@mui/material";
import { forwardRef, useEffect } from "react";
import { JobDetailInfoComp } from "./JobDetailInfoComp";
import { useDispatch, useSelector } from "react-redux";
import { GetChatMessageApi } from "../../api/GetChatMessageApi";
import { GetChatConversationAction } from "../../actions/ChatConversationAction";



const Transition = forwardRef(function Transition(props: any, ref: any) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomerDetailsComp = ({  handleDrawerOpen,handleDrawerClose,getAllJobsFun,open,messages,getMessages,single_user_data}: any) => {
  
    return (
        <Box sx={{ display: "flex", overflow: 'hidden' }} >
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
                className='desktop_drawer' >
                <JobDetailInfoComp 
                user={single_user_data} 
                handleDrawerClose={handleDrawerClose} 
                messages={messages}
                getMessages={getMessages}
                getAllJobsFun={getAllJobsFun}
                />
               
            </SwipeableDrawer>

            <Dialog
                fullScreen
                open={open}
                onClose={handleDrawerClose}
                TransitionComponent={Transition}
                className='mobile_drawer'>
                <JobDetailInfoComp user={single_user_data}
                 handleDrawerClose={handleDrawerClose} 
                 messages={messages}
                 getMessages={getMessages}
                 getAllJobsFun={getAllJobsFun}
                 />
            </Dialog>
        </Box>
    )
}