import {
    FormControl,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    FormGroup,
    Typography,
    Box,
    Divider,
    IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { districts } from "../constants/allConstants";
import { DisplayPostcodesBasedOnDistrictsComp } from '../components/PostcodesComponents/DisplayPostcodesBasedOnDistrictsComp'


export const DistrictWisePostCodesForm = ({ allDistrictPostcode, handleClose, DistrictWisePostCodeForm
}: any) => {

    const [
        createPostCodesSubmit,
        dstricts,
        handleChange,
        selectPostcodeFun,
        postcodes] = DistrictWisePostCodeForm
    return (
        <form onSubmit={createPostCodesSubmit}>
            <Grid container direction={'row'} mt={1} mb={1} >
                <Grid item xs={2}>
                    <IconButton onClick={handleClose} >
                        <CloseIcon style={{ marginBottom: 0, fontSize: 20 }} />
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <Typography component={'h4'} variant='h6' >{dstricts} Post codes</Typography>
                </Grid>
            </Grid>
            <Divider />
            <div className="" style={{ width: 350 }} >

                <Box sx={{ padding: "10px 20px 30PX 20px" }} role="presentation"  >
                    <Grid container className='postcode_drawer' >

                        <Grid item xs={12} className="post_btn_container" mt={2} justifyContent={'space-around'}>
                            <Grid item className="post_text_field">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-autowidth-label">Districts</InputLabel>
                                    <Select
                                        autoWidth
                                        label="Districts"
                                        fullWidth
                                        value={dstricts}
                                        onChange={handleChange}
                                    >
                                        {districts.map((e: any, indx: any) => {
                                            return <MenuItem key={indx} value={e}>{e}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 20, textAlign: 'left' }}  >
                            <FormGroup>
                                {districts.map((districtData: any, index: any) => {
                                    return <DisplayPostcodesBasedOnDistrictsComp
                                        dstricts={dstricts}
                                        allDistrictPostcode={allDistrictPostcode}
                                        selectPostcodeFun={selectPostcodeFun}
                                        givenDistrict={districtData}
                                        key={index}
                                        postcodes={postcodes}
                                    />
                                })}
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <div className="btn_container">
                        <Button
                            type="submit"
                            className="postcode_add_btn"
                        >
                            submit
                        </Button>
                    </div>

                </Box>
            </div>
        </form>
    )
}