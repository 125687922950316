import userIcon from "../../assets/images/userIcon.svg";
import notificationImg from "../../assets/images/notification.svg";
import scheduleweek_icon from "../../assets/images/scheduleweek_icon.svg";
import postcode_icon from "../../assets/images/postcode_icon.svg";
import payment from "../../assets/images/payment.svg";
import dashboard_icon from "../../assets/images/dashboard_icon.svg";
import bankdetail_icon from "../../assets/images/bankdetail_icon.svg";
import joblisting_icon from "../../assets/images/joblisting_icon.svg";
import { bank_details, job_listing, payments, payments_details, post_codes, userProfile, userScheduleJob, user_dashobard, user_todays_job } from "../../routersNames";

export const LeftSideBarInUserDashboardComp = ({ history }: any) => {
  const path = history.location.pathname;
  return (
    <div className="sidebar-in-bg-screen">
      <div
        className={`user_dashboard__left_side_bar ${path === user_dashobard ? "active-data" : ""
          }`}
        onClick={() => history.push(user_dashobard)}
      >
        <div>
          {" "}
          <img src={dashboard_icon} alt="" />{" "}
        </div>
        <div className="user_dashboard__left_side_bar__text">Dashboard</div>
      </div>

      <div
        className={`user_dashboard__left_side_bar ${path === userProfile ? "active-data" : ""
          }`}
        onClick={() => history.push(userProfile)}
      >
        <div>
          {" "}
          <img src={userIcon} alt="" />{" "}
        </div>
        <div className="user_dashboard__left_side_bar__text">My Account</div>
      </div>

      <div
        className={`user_dashboard__left_side_bar ${path === userScheduleJob ? "active-data" : ""
          }`}
        onClick={() => history.push(userScheduleJob)}
      >
        <div>
          {" "}
          <img src={scheduleweek_icon} alt="" />{" "}
        </div>
        <div className="user_dashboard__left_side_bar__text">Your Calender</div>
      </div>

      <div
        className={`user_dashboard__left_side_bar ${path === post_codes ? "active-data" : ""
          }`}
        onClick={() => history.push(post_codes)}
      >
        <div>
          {" "}
          <img src={postcode_icon} alt="" />{" "}
        </div>
        <div className="user_dashboard__left_side_bar__text">Post Codes</div>
      </div>
      <div
        className={`user_dashboard__left_side_bar ${path === bank_details ? "active-data" : ""
          }`}
        onClick={() => history.push(bank_details)}
      >
        <div>
          <img src={bankdetail_icon} alt="" />
        </div>
        <div className="user_dashboard__left_side_bar__text">Bank Details</div>
      </div>
      <div
        className={`user_dashboard__left_side_bar ${path === payments || path === payments_details  ? "active-data" : ""
          }`}
        onClick={() => history.push(payments)}
      >
        <div>
          {" "}
          <img src={payment} alt="" />{" "}
        </div>
        <div className="user_dashboard__left_side_bar__text">Payments</div>
      </div>
      <div
        className={`user_dashboard__left_side_bar ${path === job_listing ? "active-data" : ""
          }`}
        onClick={() => history.push(job_listing)}
      >
        <div>
          {" "}
          <img src={joblisting_icon} alt="" />{" "}
        </div>
        <div className="user_dashboard__left_side_bar__text">Your Assigned Bookings</div>
      </div>
    </div>
  );
};
