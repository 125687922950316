import { Divider, IconButton } from "@mui/material"
import { UserDashboardComp } from "../LayoutComponents/UserDashboardComp"
import dollar from "../../assets/images/dollar.svg";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CustomerDetailsComp } from "../ListOfJobsComponents/CustomerDetailsComp";
import { getSingleUserDataAction } from "../../actions/SingleUserDataAction";
import { useState } from "react";

export const PaymentsDetailsComp = () => {
    const histoy = useHistory()

    const [open, setOpen] = useState(false);

    const { paymentHistory } = useSelector((state: any) => state.paymentHistoryDetails)

    const { joblists } = useSelector((state: any) => state.bookingLists)

    const { state }: any = histoy.location


    const dispatch = useDispatch()

    const handleDrawerOpen = (custoermData: any) => {

        setOpen(true);
        dispatch(getSingleUserDataAction(custoermData))
    };

    return (
        <UserDashboardComp>
            <h4><IconButton onClick={() => histoy.goBack()} > <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>  Payment History </h4>

            {paymentHistory.filter((e) => e.payment_id === state.payment_id &&
                moment(`${state.payment_date}`, 'DD-MM-YYYY').format('MM') ==
                moment(`${e.payment_history_date}`, 'DD-MM-YYYY').format('MM')
                && moment(`${state.payment_date}`, 'DD-MM-YYYY').format('YYYY') ==
                moment(`${e.payment_history_date}`, 'DD-MM-YYYY').format('YYYY')
            ).map((job: any, index: any) => {
                return (
                    <div key={index} className='payment_details' >
                        <div className="job_listing__job-details each_payment" >
                            <div className="job_listing__job-details__calender-container date_content">
                                <div className="job_listing__job-details__calender-container__date-heading" >
                                    <div className="job_listing__job-details__calender-container__date-heading_calender-line-left"></div>
                                    <div className="job_listing__job-details__calender-container__date-heading_calender-line-right"></div>
                                    Date
                                </div>
                                <div className="job_listing__job-details__calender-container__date" >
                                    <div>
                                        <p>{moment(`${job.payment_history_date}`, 'DD-MM-YYYY').format('DD')} {moment(`${job.payment_history_date}`, 'DD-MM-YYYY').format('MMM')}</p>
                                        <p>{moment(`${job.payment_history_date}`, 'DD-MM-YYYY').format('YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="job_listing__job-details__border-after-divider divider_color" >
                                </div>
                            </div>
                            <div className="job_listing__job-details__jobseeker-details job_seeker_details">
                                <div className="job_listing__job-details__jobseeker-details__job-info" >
                                    <p>Job ID</p>
                                    <p onClick={() => {
                                        handleDrawerOpen(job)
                                    }} >{job.job_id}</p>
                                </div>
                                <div className="job_listing__job-details__jobseeker-details__job-info">
                                    <p>Time</p>
                                    <p>{job.job_time}</p>
                                </div>
                                <div className="job_listing__job-details__jobseeker-details__job-info">
                                    <p>No of Hrs</p>
                                    <p>{job.job_hours} Hr</p>
                                </div>
                                <div className="job_listing__job-details__jobseeker-details__job-info">
                                    <p>Payment Method</p>
                                    <p>{job.payment_method}</p>
                                </div>
                            </div>
                            <div className="job_listing__job-details__border-after-divider payment_details_divider" >
                            </div>
                            <div className='amount_content'>

                                <div style={{ display: 'flex', alignItems: '', justifyContent: '' }}>
                                    <img className="candidate_icon" src={dollar} />
                                    <p className="payment_data">
                                        <span className="total_amount"> Total Amount</span> <span className="amount">£{job.payment_history_total}</span>
                                    </p>
                                </div>
                                <div className="payment_sub_divided" >
                                    (Amount: £{job.payment_history_amount}, Material Cost: £{job.payment_history_material_cost}, Tip: £{job.payment_history_tip} )</div>

                            </div>
                        </div>
                        <Divider className='divider_in_mobilescreen' />
                    </div>
                )
            })}
            <CustomerDetailsComp
                open={open}
                setOpen={setOpen}
                handleDrawerOpen={handleDrawerOpen}
            />
        </UserDashboardComp>
    )
}