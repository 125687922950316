import { BankDetailsTypes } from "../constants/action_type";
export const intialState = {
  bank_details: [

  ] as any
};
export const bankDetailsReducer = (state = intialState, action) => {
  switch (action.type) {
    case BankDetailsTypes.GET_BANK_DETAILS:
      state = {
        ...state,
        bank_details: action.bankDetails as any
      }
      break;    

  }
  return state;
};                