import { chatConversationTypes } from "../constants/action_type"

const initialState = {
    messages: []
}

export const ChatConversationReducer = (state = initialState, action) => {
    switch (action.type) {
        case chatConversationTypes.CREATE_MESSAGE:
            state = {
                ...state,
                messages: [...state.messages, action.message] as any
            }
            break;
        case chatConversationTypes.GET_MESSAGE:
            state = {
                ...state,
                messages: action.message
            }
            break;
    }
    return state
}