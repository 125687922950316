// UI COMPONENTS
import { Grid, Avatar, Card } from "@mui/material";
import { Carousel } from "react-bootstrap";

// JSON Data
import { ReviewsData } from "../../ReviewsData";

export const TestimonialComp = () => {
  return (
    <div>
      <Grid
        container
        // direction={"row"}
        spacing={2}
        className="testimonial-container common-padding"
      >
        <Grid item sm={6} xs={12} className="mobile-screen landing-page__sub-content "  >
          {/* <Grid  > */}
            <div className="landing-page__form-cleaner" >
              <div className="carousel-container">
                <div className="heading">
                  <h1
                    className="landing-page__heading"
                    style={{
                      textAlign: "center",
                      fontSize: 35,
                    }}
                  >
                    Testimonials
                  </h1>

                  <Carousel
                    className="carousel"
                    controls={false}
                    indicators={false}
                    interval={1000}
                  >
                    {ReviewsData.map((item, indx) => (
                      <Carousel.Item style={{ padding: 10 }} key={indx}>
                        <Card className="landing-page__form-our-container">
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid item xs={3} md={3}>
                              <div className="landing-page__form-our-container__rightside-content">
                                <div className="landing-page__form-our-container__img">
                                  <Avatar alt="Remy Sharp" src={item.img} />
                                </div>
                                <div>
                                  <p className="landing-page__form-our-container__name">
                                    {item.name}
                                  </p>
                                </div>
                                <div>
                                  <img src={item.flag} alt="" />
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <p className="landing-page__form-our-container__content-text">
                                {item.content}
                              </p>
                            </Grid>
                          </Grid>
                        </Card>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          {/* </Grid> */}
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          className="iframe-content"
          style={{ textAlign: "right" }}
        >
          <div className="ifram-container">
            <iframe
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
              width="100%"
              className="iframes"
              title="iframe"
            ></iframe>
          </div>
        </Grid>
        <Grid item xs={12} className="desktop-screen">
          <div className="landing-page__form-cleaner">
            <h1 className="landing-page__heading">From our cleaner</h1>
            <div>
              {ReviewsData.map((item, indx) => {
                return (
                  <Card className="landing-page__form-our-container" key={indx}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        xs={3}
                        md={3}
                        alignItems={"space-around"}
                        justifyContent={"space-around"}
                        justifyItems={"space-around"}
                      >
                        <div className="landing-page__form-our-container__rightside-content">
                          <div className="landing-page__form-our-container__img">
                            <Avatar alt="Remy Sharp" src={item.img} />
                          </div>
                          <p className="landing-page__form-our-container__name">
                            {item.name}
                          </p>
                          <img src={item.flag} alt="" />
                        </div>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <p className="landing-page__form-our-container__content-text">
                          {item.content}
                        </p>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
