// main route
export const landing_page = '/'

// User
export const signUp  = '/signUp'
export const confirmation_page = '/confirmationPage'
export const login = '/login'
export const forgotPassword = '/forgotPassword'
export const recovre_password = `${forgotPassword}/recoveryPassword`

// user complete this steps after registration routes
export const tersmsAndCondtions = '/TermsAndConditions'
export const UserExperience = `/Experience`
export const success = `/success`

// user dashboard
export const dashboard_page = '/dashboard'
export const user_dashobard = `${dashboard_page}/userdashboard`
export const userProfile = `${dashboard_page}/userProfile`
export const userScheduleJob = `${dashboard_page}/scheduleJobs`
export const user_todays_job = `${dashboard_page}/todayJobs`
export const post_codes = `${dashboard_page}/postCodes`
export const bank_details = `${dashboard_page}/bankDetails`
export const edit_bank_details = `${bank_details}/editBankDetails`
export const payments = `${dashboard_page}/payments`
export const payments_details = `${payments}/PaymentsDetails`
export const job_listing = `${dashboard_page}/jobListings`

// dashboard
export const personal_information = `${userProfile}/personalInformation`
export const update_email = `${userProfile}/updateEmail`
export const update_phonenumber = `${userProfile}/updatePhoneNumber`
export const update_password = `${userProfile}/updatePassword`
export const manage_address = `${userProfile}/manageAddress`
export const terms_and_condition = `${userProfile}/termsAndCondition`
