import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { GetBankDetailsAction } from "../actions/bankDetailsAction";
import { GetBankDetailsApi } from "../api/BankDetailsApi";
import { LoaderComp } from "../components/CommonComponents/LoaderComp";
import { UserDashboardComp } from "../components/LayoutComponents/UserDashboardComp";
import { Grid, Card, Divider, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { edit_bank_details } from "../routersNames";

export const UserDashboardBankDetailsPage = () => {

  const { status } = useSelector((state: any) => state.UsersStatus);
  const { bank_details } = useSelector((state: any) => state.allBanksDetails)

  const dispatch = useDispatch()

  const getBankDetails = async (worksmanId: any) => {
    const { data } = await GetBankDetailsApi(worksmanId);
    dispatch(GetBankDetailsAction(JSON.parse(data.data).body));
  };

  const history = useHistory()

  const editBankDetails = () => {
    history.push({pathname:edit_bank_details,state:bank_details})
  }

  useEffect(() => {
    if (status.length !== 0) {
      getBankDetails(status[0].worksman_id);
    }
  }, [status]);

  return (
    <UserDashboardComp >
      <ToastContainer />
      <div className="bankDetails_container">
        <h1 className="bankDetails_heading" >Bank Details</h1>
        <Card className="card">
          {bank_details.length === 0 && <LoaderComp />}
          {bank_details.length !== 0 &&
            <div className="UserDetails">
              <div className="bankDetails">
                <Grid item xs={6}>
                  <div className="bankDetails_subheading">
                    <h4 className="_subheading">Name</h4>
                  </div>
                </Grid>
                <div className="bullets">:</div>
                <Grid item xs={6}>
                  <div className="text">{bank_details[0].account_name}</div>
                </Grid>
              </div>
              <Divider className="divider" />
              <div className="bankDetails">
                <Grid item xs={6}>
                  <div className="bankDetails_subheading">
                    <h4 className="_subheading">Sort code</h4>
                  </div>
                </Grid>
                <div className="bullets">:</div>
                <Grid item xs={6}>
                  <div className="text">{bank_details[0].sort_code}</div>
                </Grid>
              </div>
              <Divider className="divider" />
              <div className="bankDetails">
                <Grid item xs={6}>
                  <div className="bankDetails_subheading">
                    <h4 className="_subheading">Account Number</h4>
                  </div>
                </Grid>
                <div className="bullets">:</div>
                <Grid item xs={6}>
                  <div className="text">{bank_details[0].account_number}</div>
                </Grid>
              </div>
              <Divider className="divider" />
              <div className="bankDetails">
                <Grid item xs={6}>
                  <div className="bankDetails_subheading">
                    <h4 className="_subheading">Bank</h4>
                  </div>
                </Grid>
                <div className="bullets">:</div>
                <Grid item xs={6}>
                  <div className="text">{bank_details[0].bank_name}</div>
                </Grid>
              </div>
              <Divider className="divider" />
              <Button className="btn" variant="contained" onClick={editBankDetails}>
                Edit
              </Button>
            </div>
          }
        </Card>
      </div>
    </UserDashboardComp>
  );
};
