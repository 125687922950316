
import { useEffect, useState } from "react";
import { HeaderNavComp } from "./components/LayoutComponents/HeaderNavComp";
import Amplify, { Auth } from "aws-amplify";
import "./assets/scss/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { AwsConfig } from "./config/AwsConfig";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { UserStatusActions } from "./actions/UserStatusActions";
import { UserCurrentStatusApi } from "./api/UserCurrentStatusApi";

// aws amflifyer
Amplify.configure(AwsConfig);

const App = () => {

  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch()

  const getUserStatus = async () => {
    try {
      const sessions: any = await Auth.currentSession();
      if (sessions.idToken.payload) {
        setUserInfo(sessions.idToken.payload);
        const { email } = sessions.idToken.payload;
        const currentStep = await UserCurrentStatusApi(email);
        dispatch(UserStatusActions(JSON.parse(currentStep.data.data).body))
        // RedirectionHelper(currentStep); 

      }
    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }
  // Effects
  useEffect(() => {
    getUserStatus();
  }, []);






  return (
    <>
      <div>
        <HeaderNavComp userInfo={userInfo} />
        <div className="main_container">
          <div className="body-center after_header_body">

            <PublicRoutes />
            <PrivateRoutes />


          </div>
        </div>
      </div>
    </>
  );
};

export default App;



