import axios from "axios";
import { envDevUrl, envUrl } from "../constants/allConstants";
import { jwtToken } from "../constants/jwtToken";

export const GetBankDetailsApi = async (worksmanId: any): Promise<any> => {

  try {
    const config = {
      headers: {
        worksman_id: worksmanId,
        Authorization: await jwtToken()
      }
    }
    return await axios.get(`${envDevUrl}/GetBankDetailsApi`, config)
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
};