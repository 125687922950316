import { stat } from "fs";
import { scheduleActionTypes } from "../constants/action_type";


const initialState = {
    scheduleData : []
}

export const UserScheduleReducers = (state=initialState,action:any) => {
    switch(action.type){
        case scheduleActionTypes.GET_SCHEDULE:
            state={
                ...state,
                scheduleData:[...action.payload] as any
            }
    }
    return state
}