export const AwsConfig = {
    Auth: {
      mandatorySignId: true,
      region: "us-east-2",
      userPoolId: "us-east-2_ix0KK3tkN",
      userPoolWebClientId: "1e92vk7h6e01mdb8r6v24hmg2i",
    }
  }

  // us-east-2_snRnfRZ3a
  // 79ap4efdom2hoe1eb103luclqe