import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const getJobsBasedOnMonthsApi = async (worksmanId: any, monthYear: any): Promise<any> => {
   console.log("monthYear",monthYear)
    try {
        const config = {
            headers: {
                worksman_id: worksmanId,
                booking_slot_date: monthYear,
                Authorization: await jwtToken()
            }
        }
        const data = await axios.get(`${envDevUrl}/GetBookingTableByDateApi`, config)
        return data
    } catch (exception) {
        throw exception
    }
}
