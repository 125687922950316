import React from "react";
import { Grid, Divider, IconButton } from "@mui/material";
import GreenIcon from "../assets/images/GreenIcon.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";

export const TermsConditionPage = () => {
  const history = useHistory();
  return (
    <Grid
      container
      justifyContent="space-around"
      className="TermsCondition_container"
    >
      <form>
      <div className="backIcon" onClick={() => history.goBack()}>
        <IconButton className="IconButton">
          <ArrowBackIosIcon className="arrowIcon" />
          Back
        </IconButton>
      </div>
      <Grid className="TermsCondition_sub_container">
        <div className="terms_condition_page">
        <h1 className="Terms_Condition_heading">Terms &amp; Conditions</h1>
        <h4 className="eligibility">
          User has accepted eligibility of working in united kingdom
        </h4>
        <Divider className="divider" />

        <div className="Terms_condition_checked">
          <img className="greenTick" alt="" src={GreenIcon} />
          <p className="text">I've watched "How Vrishkar works" video</p>
        </div>
        <Divider className="divider" />
        <div className="Terms_condition_checked">
          <img className="greenTick" alt="" src={GreenIcon} />
          <p className="text">I understand this is a self-employed role</p>
        </div>
        <Divider className="divider" />
        <div className="Terms_condition_checked">
          <img className="greenTick" alt="" src={GreenIcon} />
          <p className="text">
            I understand Vrishkar isn't responsible for any damage at the
            customers premises
          </p>
        </div>
        <Divider className="divider" />
        <Grid className="Right_To_Work_container">
          <h1 className="right_to_work_heading">Right To Work</h1>
          <h4 className="eligibility">
            User has accepted right of working in united kingdom
          </h4>
          <Divider className="divider" />
          <div className="Terms_condition_checked">
            <img className="greenTick" alt="" src={GreenIcon} />
            <p className="text">I have right to work in Uk</p>
          </div>
          <Divider className="divider" />
          <div className="Terms_condition_checked">
            <img className="greenTick" alt="" src={GreenIcon} />
            <p className="text">I don't have any criminal records</p>
          </div>
          <Divider className="divider" />
          <div className="Terms_condition_checked">
            <img className="greenTick" alt="" src={GreenIcon} />
            <p className="text">I have bank account</p>
          </div>
          <Divider className="divider" />
        </Grid>
      </div>
      </Grid>
      </form>
    </Grid>
  );
};
