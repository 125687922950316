import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScheduleAction } from "../actions/UserScheduleActions";
import { deleteScheduleJobApi } from "../api/deleteScheduleJobApi";
import { GetWorksmanScheduleApi } from "../api/GetWorksmanScheduleApi";
import { UserDashboardComp } from "../components/LayoutComponents/UserDashboardComp";
import { BookedScheduleDataComp } from "../components/SchedulejobsComponents/BookedScheduleDataComp";
import { ScheduleDrawerComp } from "../components/SchedulejobsComponents/ScheduleDrawerComp";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Grid } from "@mui/material";
import CalendarPicker from "@mui/lab/CalendarPicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { createWorksmanScheduleApi } from "../api/createWorksmanScheduleApi";
import moment from "moment";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";



export const ScheduleJobPage = () => {
  const intialArray: any[] = [];
  const { scheduleData } = useSelector((state: any) => state.scheduleJobs);
  const { status } = useSelector((state: any) => state.UsersStatus);
  const [worksManCurrentAvailability, setWorksManCurrentAvailability] =
    useState(intialArray);


    console.log("scheduleDatascheduleData",scheduleData)

  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const submitWorksmanCurrentAvailability = async (e) => {
    e.preventDefault();
    const arrayData = {
      worksman_id: `${status[0].worksman_id}`,
      schedule_date: moment(date).format('DD MMM YYYY'),
      schedule_time: worksManCurrentAvailability,
      is_active: 1
    }



    console.log("array data", arrayData)
    const { data }: any = await createWorksmanScheduleApi(arrayData)
    // getScheduleData(status[0].worksman_id)
    getScheduleData(status[0].worksman_id);
    const { statusCode, body } = JSON.parse(data.data)
    console.log("This stored data", JSON.parse(data.data))
    if (statusCode == 200) {
      handleClose()
    }
  };

  const storeWorksmanCurrentAvailability = (time: any, selectedDate: any) => {
    let userAvailableArray: any;
    console.log(worksManCurrentAvailability.includes(time), worksManCurrentAvailability)
    if (!worksManCurrentAvailability.includes(time)) {
      userAvailableArray = [...worksManCurrentAvailability, time];
      setWorksManCurrentAvailability(userAvailableArray);
    } else {
      const filterAvailibility = worksManCurrentAvailability.filter(
        (availibility: any) => availibility !== time
      );
      console.log("filterAvailibility" + filterAvailibility);
      setWorksManCurrentAvailability(filterAvailibility);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // return scheduleDrawerData.length = 0
  };

  const deleteScheduleJob = async (scheduleJob: any) => {
    const {schedule_id} = scheduleJob
    
    try {
      const deleteData = await deleteScheduleJobApi(schedule_id);
      const { statusCode } = JSON.parse(deleteData.data)
      getScheduleData(status[0].worksman_id);
      if (statusCode === 200) {
        // toast.success('Data deleted successfully')
      }
      console.log("Delete schedule job", statusCode)
    } catch (exception) {
      throw exception;
    }
  };

  // get worksman schedule data based on date 
  const getScheduleDateBasedOnDate = async (newDate: any, worksmanId: any) => {
    setDate(newDate);
    try {
      const { data } = await GetWorksmanScheduleApi(worksmanId, newDate);
      const worksmanScheduledData = JSON.parse(data.data).body
      const userAvailableArray = worksmanScheduledData.map(
        (scheduledInfo: any) => {
          return scheduledInfo.worksman_schedule_time
          // return scheduledInfo;
        }
      );
      setWorksManCurrentAvailability(userAvailableArray);
      console.log("worksmanScheduledDataworksmanScheduledData", userAvailableArray)
      dispatch(getScheduleAction(worksmanScheduledData));
    } catch (exception) {
      throw exception;
    }
  }

  // worksman schedules
  const getScheduleData = async (worksmanId) => {
    console.log("worksmanId", typeof (worksmanId), date)
    try {
      const { data } = await GetWorksmanScheduleApi(worksmanId, date);

      console.log("getScheduleData", data)
      const worksmanScheduledData = JSON.parse(data.data).body
      const userAvailableArray = worksmanScheduledData.map(
        (scheduledInfo: any) => {
          return scheduledInfo.schedule_time;
        }
      );
      setWorksManCurrentAvailability(userAvailableArray);
      console.log("worksmanScheduledDataworksmanScheduledData", worksmanScheduledData)
      dispatch(getScheduleAction(worksmanScheduledData));
      // dispatch(getScheduleAction(JSON.parse(data.data).body))
    } catch (exception) {
      console.log("This new error", exception)
      throw exception;
    }
  };

  useEffect(() => {
    if (status.length !== 0) {
      getScheduleData(status[0].worksman_id);
    }
  }, [status]);

  return (
    <UserDashboardComp>
      <>
        {/* <ToastContainer /> */}
        <div className="schedule_calendar">
          <div>
            <div className="schedule_container">
              <div className="booked_schedule_data_in_desktop">
                <BookedScheduleDataComp
                  date={date}
                  handleClickOpen={handleClickOpen}
                  scheduleData={scheduleData}
                  deleteScheduleJob={deleteScheduleJob}
                />
              </div>

              <div className="col-md-6">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12} md={6}>
                    <CalendarPicker
                      date={date}
                      onChange={(newDate: any) => {
                        if (status.length !== 0) {
                          getScheduleDateBasedOnDate(newDate, status[0].worksman_id)
                        }
                      }}
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
              <div className="booked_schedule_data_in_mobile">
                <BookedScheduleDataComp
                  date={date}
                  handleClickOpen={handleClickOpen}
                  scheduleData={scheduleData}
                  deleteScheduleJob={deleteScheduleJob}
                />
              </div>
            </div>

            <ScheduleDrawerComp
              handleClose={handleClose}
              date={date}
              open={open}
              handleClickOpen={handleClickOpen}
              storeWorksmanCurrentAvailability={storeWorksmanCurrentAvailability}
              submitWorksmanCurrentAvailability={
                submitWorksmanCurrentAvailability
              }
              worksManCurrentAvailability={worksManCurrentAvailability}
            />
          </div>
        </div>
      </>
    </UserDashboardComp>
  );
};
