import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserdetailsAction } from "../actions/getUserdetailsAction";
import { UserAccountInfoApi } from "../api/UserAccountInfoApi";
import { UserDashboardComp } from "../basmodule";
import { manage_address, personal_information, terms_and_condition, update_email, update_password, update_phonenumber } from "../routersNames";
import { UserAccountDividerWithTextComp } from "../components/AccountInfoComponents/UserAccountDividerWithTextComp";
import { useHistory } from "react-router-dom";


export const AccountInfoPage = () => {

  const { status } = useSelector((state: any) => state.UsersStatus)

  const history = useHistory()
  const dispatch = useDispatch()

  // status
  const getUserDetails = async (workmanId: any) => {
    try {
      const { data } = await UserAccountInfoApi(workmanId)
      dispatch(GetUserdetailsAction(JSON.parse(data.data).body))

    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  useEffect(() => {
    if (status.length !== 0) getUserDetails(status[0].worksman_id)
  }, [])

  return (
    <UserDashboardComp>
      <div className="personal_info_heading">
        <div className="">
          <h2 className="useraccount-heading">Your Profile Information</h2>
          <div className="personal_info_container">
            <UserAccountDividerWithTextComp
              text={"Personal Information"}
              link={() => history.push({ pathname: personal_information, state: status })}
            />
            <UserAccountDividerWithTextComp
              text={"Change Email"}
              link={() => history.push(update_email)}
            />
            <UserAccountDividerWithTextComp
              text={"Change Phone Number"}
              link={() => history.push(update_phonenumber)}
            />
            <UserAccountDividerWithTextComp
              text={"Change Password"}
              link={() => history.push(update_password)}
            />
            <UserAccountDividerWithTextComp
              text={"Manage Address"}
              link={() => history.push(manage_address)}
            />
            <UserAccountDividerWithTextComp
              text={"Terms & Condition"}
              link={() => history.push(terms_and_condition)}
            />
          </div>
        </div>
      </div>
    </UserDashboardComp>
  );
};
