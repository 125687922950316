import {
    Button,
    TextField,
    Stack
  } from "@mui/material";
import { errorMsg } from "../constants/errorMsg";
export const ConfirmationForm = ({onSubmit,register,handleSubmit,errors}:any) => {
  return (
    
    <form onSubmit={handleSubmit(onSubmit)}>
    <TextField
      fullWidth
      label="Enter OTP"
      defaultValue=""
      {...register("otp", {
        required: errorMsg,
        maxLength: {
          value: 6,
          message: " Enter a valid otp",
        },
        minLength: {
          value: 6,
          message: "Enter a valid otp",
        },
      })}
      id="otp"
      className='text-field'
    />
    <div style={{ textAlign: 'right' }}>
      {errors.otp && (
        <span className="confirmation-errors">
          {errors.otp.message}
        </span>
      )}
    </div>
    <div className="button">
      <Stack spacing={2} direction="row">
        <Button variant="outlined" type="submit" className="proceed_btn">
          Proceed
        </Button>
      </Stack>
    </div>
  </form>
  )
}
