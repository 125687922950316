import React from "react";
import { Card, Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const ManageAddress = () => {
  const history = useHistory();

  const userAddress = useSelector((state: any) => state.userDetails.userDetails.addressDetails)

  return (
    <div className="manageAddress_container">
      <form>
        <div className="backIcon" onClick={() => history.goBack()}>
          <IconButton className="IconButton">
            <ArrowBackIosIcon className="arrowIcon" />
            Back
          </IconButton>
        </div>
        <Card className="card">
          <h1 className="manageAddress_heading">Manage Address</h1>
          <h3 className="subheading">Home</h3>
          <Grid className="address" >
            <p className="Home_address">{userAddress !== undefined && userAddress[0].first_line}</p>
            <p className="Home_address">{userAddress !== undefined && userAddress[0].second_line}</p>
            <p className="Home_address">{userAddress !== undefined && userAddress[0].county}</p>
            <p className="Home_address">{userAddress !== undefined && userAddress[0].city}</p>
            <p className="Home_address">{userAddress !== undefined && userAddress[0].postcode}</p>
          </Grid>
        </Card>
      </form>
    </div >
  );
};
