import { paymentHistoryDetailsActionType } from "../constants/action_type";

const intialState = {
    paymentHistory: []
}

export const paymentHistoryDetailsReducer = (state = intialState, action) => {
    switch (action.type) {
        case paymentHistoryDetailsActionType.GET_PAYMENT_HISTORY_DETAIL:
            state = {
                ...state,
                paymentHistory: action.payload
            }
    }
    return state
}