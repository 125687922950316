import { errorMsg } from "../constants/errorMsg";
import { emailRegex } from "../constants/RegExp";
import { Button, TextField } from "@mui/material";

export const AccountRecoveryForm = ({ forgotPasswordFun,errors ,handleSubmit,register}: any) => {

    return (
        <form onSubmit={handleSubmit(forgotPasswordFun)}>
            <div>
                <TextField
                    label="Enter email"
                    className="text-field"
                    type="email"
                    fullWidth
                    {...register("email", {
                        required: errorMsg,
                        pattern: {
                            value: emailRegex,
                            message: "Enter valid Email Id",
                        },
                    })}
                />
                <div className="error_msg">
                    {errors.email && (
                        <span className="errors">{errors.email.message}</span>
                    )}
                </div>
            </div>
            <div>
                <Button className="common_button" type="submit">
                    Next
                </Button>
            </div>
        </form>
    )
}