import { Button } from "@mui/material";
import { CleaningServiceComp } from "../components/ExperiencePageComponents/CleaningServiceComp";
import { DocumentsComp } from "../components/ExperiencePageComponents/DocumentsComp";
import { EligibilityToWorkComp } from "../components/ExperiencePageComponents/EligibilityToWorkComp";

export const ExperienceForm = ({ experiencePageSubmit, handleSubmit, checkboxError, register, errors }: any) => {
  return (
    <form onSubmit={handleSubmit(experiencePageSubmit)}>
      <CleaningServiceComp
        register={register}
        errors={errors}
        checkboxError={checkboxError}
      />
      <hr />
      <EligibilityToWorkComp register={register} errors={errors} />
      <hr />
      <DocumentsComp register={register} errors={errors} />

      <div className="experience_content">
        <div className="experience_content_elements btn-container">
          <Button className="btn-join experience_btn" type="submit">
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};
