import { paymentDetailsActionType } from "../constants/action_type";

const initialState = {
    paymentDetails: []
}

export const PaymentsDetailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case paymentDetailsActionType.GET_PAYMENT_DETAIL:
            state = {
                ...state,
                paymentDetails: action.payload
            }
    }

    return state
}