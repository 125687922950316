import { FormControlLabel } from "@mui/material"
import { IOSSwitch } from "./IosSwitchComp"

export const DisplayPostcodesBasedOnDistrictsComp = ({
    dstricts,
    allDistrictPostcode,
    selectPostcodeFun,
    givenDistrict,
    postcodes
}: any) => {
    return (
        <>
            {dstricts === givenDistrict &&
                allDistrictPostcode.filter((e: any) => {
                    return e.district === givenDistrict
                }).map((e: any, index: any) => {
                    const selectedPostcodes = postcodes.some((ptcode: any) => {
                        return (ptcode.postcodes == e.postcodes) && (ptcode.district == e.district)
                    })
                    return <FormControlLabel key={index} control={<IOSSwitch
                        sx={{ m: 2 }}
                        defaultChecked={selectedPostcodes ? true : false}
                        onChange={(event: any) => {

                            selectPostcodeFun(event, e)
                        }}
                    />} label={e.postcodes} />
                })}
        </>
    )
}