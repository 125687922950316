
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Divider } from "@mui/material";
import { Rating } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from 'moment'
import { UpdateStatusApi } from '../../api/UpdateStatusApi';

export const WorksmanJobListingReviewsComp = ({ onopen, allJobsData }: any) => {
console.log("allJobsData",allJobsData)
  return (
        <>
            {allJobsData !== undefined &&
                allJobsData.map((job: any, index: any) => {
                    return (
                        <div key={index} >
                            {index === 0 &&
                                <Divider style={{ marginTop: 20 }} light className='divider_in_mobilescreen' />
                            }
                            <div className="job_listing__job-details" >
                                <div className="job_listing__job-details__calender-container">
                                    <div className="job_listing__job-details__calender-container__date-heading" >
                                        <div className="job_listing__job-details__calender-container__date-heading_calender-line-left"></div>
                                        <div className="job_listing__job-details__calender-container__date-heading_calender-line-right"></div>
                                        Date
                                    </div>
                                    <div className="job_listing__job-details__calender-container__date" >
                                        <div>
                                            <p>{moment(`${job.booking_slot_date}`).format('DD')} {moment(`${job.booking_slot_date}`).format('MMM')}</p>
                                            <p>{moment(`${job.booking_slot_date}`).format('YYYY')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="job_listing__job-details__border-after-divider" >
                                    </div>
                                </div>
                                <div className="job_listing__job-details__jobseeker-details">
                                    <div className="job_listing__job-details__jobseeker-details__job-info" >
                                        <p>Job Title</p>
                                        <p>{job.booking_title}</p>
                                    </div>
                                    <div className="job_listing__job-details__jobseeker-details__job-info" >
                                        <p>Job status</p>
                                        <p>{job.booking_status}</p>
                                    </div>
                                    <div className="job_listing__job-details__jobseeker-details__job-info">
                                        <p>Time</p>
                                        <p>{job.booking_slot_time}</p>
                                    </div>
                                    <div className="job_listing__job-details__jobseeker-details__job-info">
                                        <p>No of Hrs</p>
                                        <p>{job.no_of_hours} Hr</p>
                                    </div>
                                </div>
                                <div className='job_listing__job-details__jobseeker-details__reviews'>
                                    <div className='job_listing__job-details__jobseeker-details__reviews__mob-version-arrow'>
                                        <IconButton onClick={() => onopen(job)} >
                                            <ArrowForwardIosIcon className={`job_listing__right_arrow`} />
                                        </IconButton>
                                    </div>
                                    <p>Customer reviews</p>
                                    
                                    <div>
                                        <Rating name="half-rating-read" readOnly
                                                defaultValue={job.user_ratings} precision={0.5}
                                            />
                                    </div>
                                </div>
                                <div className='job_listing__job-details__jobseeker-details__reviews__desktop-version-arrow'>
                                    <IconButton onClick={() => onopen(job)}  >
                                        <ArrowForwardIosIcon className={`job_listing__right_arrow`} />
                                    </IconButton>
                                </div>
                            </div>
                            <Divider className='divider_in_mobilescreen' />
                        </div>
                    )
                })}
        </>
    )

}