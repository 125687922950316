import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { LeftSideBarInUserDashboardComp } from "../DashBoardComponents/LeftSideBarInUserDashboardComp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { UserStatusActions } from "../../actions/UserStatusActions";
import { UserCurrentStatusApi } from "../../api/UserCurrentStatusApi";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import { tersmsAndCondtions, UserExperience, user_dashobard } from "../../routersNames";
import { WorksmanInformationComp } from "../../pages/DashboardPage";
import { LoaderComp } from "../CommonComponents/LoaderComp";
import { AllJobsApi } from "../../api/AllJobsApi";
import { GetJobListingAction } from "../../actions/JobListingAction";
import { PaymentsDetailsApi } from "../../api/PaymentsDetailsApi";
import { paymentDetailsAction } from "../../actions/paymentDetailsAction";
import { paymentHistoryDetailsAction } from "../../actions/paymentHistoryDetailsAction";
import { PaymentHistoryDetailApi } from "../../api/PaymentHistoryDetailApi";
import { UserAccountInfoApi } from "../../api/UserAccountInfoApi";
import { GetUserdetailsAction } from "../../actions/getUserdetailsAction";

export const UserDashboardComp = ({ ...props }: any) => {
  const history = useHistory();

  const { status } = useSelector((state: any) => state.UsersStatus);

  const states = useSelector((state: any) => state)

  const dispatch = useDispatch()

  const path = history.location.pathname;

  const userStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const currentStep = await UserCurrentStatusApi(user.attributes.email);
      console.log("currentStep", currentStep)
      let userStatus = JSON.parse(currentStep.data.data).body
      dispatch(UserStatusActions(userStatus))
    } catch (exception) {
      throw exception;
    }
  }

  useEffect(() => {
    if (status.length !== 0) {
      userStatus()
    }

  }, []);
  // status


  // User details
  const getUserDetails = async (workmanId: any) => {
    try {
      const { data } = await UserAccountInfoApi(workmanId)
      dispatch(GetUserdetailsAction(JSON.parse(data.data).body))

    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  useEffect(() => {
    if (status.length !== 0) getUserDetails(status[0].worksman_id)
  }, [])




  // List of jobs
  const getAllJobs = async (worksman_id: any) => {
    try {
      const { data } = await AllJobsApi(worksman_id)

      const { body } = JSON.parse(data.data)
      console.log("bodybody", data)
      dispatch(GetJobListingAction(body))

    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }

  useEffect(() => {
    if (status.length !== 0) {
      getAllJobs(status[0].worksman_id)
    }
  }, [status])


  // payment details
  const getPaymentDetails = async (worksman_id: any) => {

    try {
      const { data }: any = await PaymentsDetailsApi(worksman_id)

      dispatch(paymentDetailsAction(JSON.parse(data.data).body))

    } catch (exception) {
      throw exception
    }
  }

  useEffect(() => {
    if (status.length !== 0) {
      getPaymentDetails(status[0].worksman_id)
    }
  }, [status])



  // payment history details
  const getPaymentHistoyDetails = async (worksman_id: any) => {
    try {
      const { data }: any = await PaymentHistoryDetailApi(worksman_id)

      dispatch(paymentHistoryDetailsAction(JSON.parse(data.data).body))

    } catch (exception) {
      throw exception
    }
  }
  useEffect(() => {
    if (status.length !== 0) {
      getPaymentHistoyDetails(status[0].worksman_id)
    }
  }, [status])


  return (
    <>
      <div className="user_dashboard">
        {status.length === 0 && <LoaderComp />}

        {status.length !== 0 && (
          <>
            {status[0].current_step === "worksmanexperience" && (
              <>
                <Grid container >
                  <Grid className="user_dashboard_sidebar" >
                    <LeftSideBarInUserDashboardComp history={history} />
                  </Grid>

                  <Grid
                    className="useraccount-container"
                  >
                    {path === user_dashobard && (
                      <div className="">
                        <WorksmanInformationComp />
                      </div>
                    )}
                    {props.children}
                  </Grid>
                </Grid>
              </>
            )}

            {status[0].current_step === "worksmanRegistration" && <Redirect to={tersmsAndCondtions} />}
            {status[0].current_step === "worksman-T&C" && <Redirect to={UserExperience} />}
          </>

        )}

      </div>
    </>
  );
};

