import { GetUserdetailsTypes } from "../constants/action_type";

const initialStatus = {
    userDetails: {}
}

export const GetUserDetailsReducer = (state = initialStatus, action) => {
    switch (action.type) {
        case GetUserdetailsTypes.GET_USER_DETAILS:
            state = {
                ...state,
                userDetails: action.userDetails
            }
    }
    return state
}