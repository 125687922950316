import { Card, Divider, Grid } from "@mui/material"
import arrowRight from "../../assets/images/arrowRight.svg";
import search from "../../assets/images/search.svg";
import helpIcon from "../../assets/images/helpIcon.svg";
import feedback from "../../assets/images/feedback.svg";

export const DashboardHelpSectionComp = () => {
    return (

        <Card className="help_container" >
            <div className="help_content">
                <h1 className="heading_help">Looking For Something Else?</h1>
                <div className="help">
                    <div className="icon_text">
                        <div className="help_icons">
                            <img src={search} />
                        </div>
                        <div className="help_text">
                            Search your Account
                        </div>
                    </div>
                    <div className="arrow_img"><img className="arrowRight" src={arrowRight} /></div>
                </div>
                <Divider className="help_divider" />
                <div className="help">
                    <div className="icon_text">
                        <div className="help_icons" >
                            <img src={helpIcon} />
                        </div>
                        <div className="help_text">
                            See help options
                        </div>
                    </div>
                    <div className="arrow_img"><img className="arrowRight" src={arrowRight} /></div>
                </div>
                <Divider className="help_divider" />
                <div className="help">
                    <div className="icon_text">
                        <div className="help_icons">
                            <img src={feedback} />
                        </div>
                        <div className="help_text">
                            Send Us Feedback
                        </div>
                    </div>
                    <div className="arrow_img"><img className="arrowRight" src={arrowRight} /></div>
                </div>
            </div>
        </Card>
    )
}