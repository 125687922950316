import {
  AppBar,
  Avatar,
  Box,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import vrishkarLogo from "../../assets/images/vrishkar-logo.svg";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import {  useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { landing_page } from "../../routersNames"


export const HeaderNavComp =({ userInfo }: any) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(false);

  const handleMenu = () => {
    setAnchorEl(!anchorEl);
  };


  const { pathname } = history.location

  const LogOut = async () => {
    try {
      const logOut = await Auth.signOut();
      window.location.href = "/"
      setAnchorEl(false);
      if (logOut) {
        history.push(landing_page)
      }

    } catch (exception: any) {
      console.log("This is logout error", exception);
      throw exception;
    }
  }

  return (
    <div className="navbar_container">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" className="header_bar" >
          <Toolbar className="nav-header">
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}

            >
              <img src={vrishkarLogo} alt="" onClick={() => {
                // history.push('/')         
                window.location.href = "/"
              }} />
            </Typography>

            <div>
              {(userInfo && Object.keys(userInfo).length !== 0) &&

                <div onClick={handleMenu} className="user_name">
                  <AccountCircleIcon className="icon_color mobile-screen" />
                  <span className="nav-header__right-text mobile-screen">
                    Hello {userInfo.given_name}

                  </span>
                  <Avatar className="user_avatar big-screen" variant="circular">
                    {userInfo.given_name.slice(0, 1).toUpperCase()}

                  </Avatar>
                </div>

              }
              {!userInfo && window.location.pathname != "/login" &&

                <>
                  &nbsp;
                  <Button
                    variant="outlined"
                    onClick={() => {
                      // history.push('/login')     
                      window.location.href = "/login"
                    }}
                    className="login_btn"
                  >
                    Login
                  </Button>
                </>
              }

              {anchorEl && (
                <div className="drop_down_content">
                  <div className="drop_down_text" onClick={LogOut}>Logout</div>
                </div>
              )}
            </div>

          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );

}
