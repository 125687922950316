import { Auth } from "aws-amplify";
import axios from "axios";
import { envDevUrl } from "../constants/allConstants";
import { jwtToken } from "../constants/jwtToken";

export const updatePhoneNumberApi = async (worksman_id: any, phone: any): Promise<any> => {
    try {

        const config = {
            headers:{
                Authorization: await jwtToken()
            }
        }
        let data = await axios.put(`${envDevUrl}/UpdateWorksmanDetailsApi`, {
            worksman_id: worksman_id,
            phone: phone
          },config)
        return data

    } catch (exception) {
        throw exception
    }
}