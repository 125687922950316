import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const BookedScheduleDataComp = ({ date, deleteScheduleJob, handleClickOpen, scheduleData }: any) => {


    const bookedScheduleData = scheduleData.filter((e: any) => {
      return moment(e.worksman_schedule_date).format('DD-MMM-YYYY') == moment(date).format('DD-MMM-YYYY')
    })

    return (

        <div className="toast_message">
            <div className="booked_schedule_data">
                <h6>{moment(date).format('dddd,MMMM DD')}</h6>

                <IconButton onClick={handleClickOpen} className='book_icon_button'>
                    {bookedScheduleData.length !== 0 ?
                        <ModeEditIcon className="book_icon common_icon" /> :
                        <AddCircleOutlineIcon className="book_icon common_icon" />}

                </IconButton>
            </div>
            <div>

                {scheduleData.filter((e: any) =>
                    moment(e.schedule_date).format('DD-MMM-YYYY') == moment(date).format('DD-MMM-YYYY'))
                    .map((scheduleJob: any, indx: any) => {

                        return (
                            <div className="booked_content" key={indx}>
                                <div className="time">
                                    <div className="line">
                                        <div className="top_circle"></div>
                                        <div className="bottom_circle" ></div>
                                        <div className="top_time">{scheduleJob.schedule_time.split('-')[0]}</div>
                                        <div className="bottom_time" >{scheduleJob.schedule_time.split('-')[1]}</div>
                                    </div>
                                </div>
                                <div className="booked">
                                    <div>
                                        Booked <CheckCircleRoundedIcon className="checked_icon common_icon " fontSize="small" />
                                    </div>

                                    <div className="delete_content">
                                        <Tooltip title="Delete" arrow followCursor >
                                            <IconButton onClick={() => deleteScheduleJob(scheduleJob)}>
                                                <HighlightOffIcon className="common_icon" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}