import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { JobDetailsCustomerInfoComp } from "./JobDetailsCustomerInfoComp";
import { CustomerAndWorkmanChatComp } from "./CustomerAndWorkmanChatComp";

import CloseIcon from '@mui/icons-material/Close';

export const JobDetailInfoComp = ({ user, handleDrawerClose,messages,getMessages,getAllJobsFun }: any) => {

    return (
        <>
            <Grid container mt={2} mb={2} sx={{ justifyContent: 'space-around', display: 'flex' }} >
                <Grid item xs={1} pl={2} >
                    <IconButton onClick={handleDrawerClose} >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={11}>
                    <Typography sx={{ textAlign: 'center' }} variant="h5" component="h5"> Customer Information</Typography>
                </Grid>
            </Grid>
            <Divider />
            <div className="drawer_container">
                <JobDetailsCustomerInfoComp user={user} getAllJobsFun={getAllJobsFun} />
                <CustomerAndWorkmanChatComp messages={messages} getMessages={getMessages} user={user}/>
            </div>
        </>
    )
}