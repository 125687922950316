
import Amplify, { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { emailRegex } from "../constants/RegExp";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { updateEmailApi } from "../api/updateEmailApi";
import { ChangeEmailForm } from "../forms/ChangeEmailForm";


export const ChangeEmailPage = () => {

  const [email, setEmail] = useState("")
  const [emailUpdate, setEmailUpdate] = useState({ newEmail: "", reEnterEmail: "" })
  const [otpBox, setOtpBox] = useState(false);
  const { status } = useSelector((state: any) => state.UsersStatus)


  const handleChange = (e: any) => {
    setEmailUpdate({ ...emailUpdate, [e.target.name]: e.target.value })
  }

  const onBlur = async (e: any) => {
    let value = e.target.value;

    try {
      if (value.match(emailRegex)) {
        if (email !== value) {
          if (emailUpdate.newEmail === emailUpdate.reEnterEmail) {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, { email: email });
            toast.success('Otp send succesfully you enter email')
            setOtpBox(true)
          } else {
            toast.error("Email not matched")
          }
        } else {
          toast.error("Please Enter new Email")
        }
      } else {
        toast.error('Please Enter Valid email pattern')
      }
    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  }


  const ChangeEmail = async (data: any) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.verifyUserAttributeSubmit(user, 'email', data.Otp)
      await updateEmailApi(status[0].worksman_id, data.email)
      toast.success("Email changed successfully")
    } catch (exception) {
      toast.error(`${exception}`)
      console.log(exception);
      throw exception;
    }
  };

  useEffect(() => {
    const emailData = async () => {
      const user = await Auth.currentAuthenticatedUser();
      setEmail(user.attributes.email)
    }
    emailData()
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="email_page common-styles">
        <ChangeEmailForm
          ChangeEmail={ChangeEmail}
          email={email}
          onBlur={onBlur}
          emailUpdate={emailUpdate}
          handleChange={handleChange}
          otpBox={otpBox} />
      </div>
    </>
  );
};
