import { Grid } from "@mui/material";
import termsIcon from "../../assets/images/termsIcon.svg";

export const TermsAndConditionCommon = () => {
  return (
    <>
      <div className="Terms_Condition_Sub_Container">
        <div className="icons-terms_and_condition">
          <Grid textAlign="center">
            <img
              alt=""
              className="number_icons"
              src={termsIcon}
              
            />
          </Grid>
        </div>
        <h1 className="heading-How_it_works" >How it Works?</h1>
        <h1 className="heading-Hassle_free_houseCleaning" >Hassle-free house cleans </h1>
        <div className="TermsConditionPage_container__letter-spacing">
          <p className="TermsAndCondition-text">
            Please watch this video to know how vrishkar works?
          </p>
          <p className="watch-text">
            Please watch the whole video with sound on
          </p>
        </div>
        <Grid className="iframe-content">
          <div className="ifram-container">
            <iframe
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
              width="100%"
              className="iframes"
              title="iframe"
            ></iframe>
          </div>
        </Grid>
      </div>
    </>
  );
};
