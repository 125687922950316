import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const PaymentHistoryDetailApi = async (worksmanId: any): Promise<any> => {
    try {
        const config = {
            headers: {
                Authorization: await jwtToken(),
                worksman_id: worksmanId
            }
        }
        let data = await axios.get(`${envDevUrl}/GetPaymentHistoryDetailsApi`, config)
        return data

    } catch (exception) {
        throw exception
    }
}