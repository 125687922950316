import moment from "moment"
import { dateHelpers } from "./dateHelpers"

export const WorksmanJobListingReviewsHelper = (jobType: any, joblists: any,
    //  showMonth: any, showYear: any
    bookingDate
     ) => {
    try {
        if (jobType === 'Today Jobs') {
            return joblists.filter((e: any) => moment(`${e.booking_slot_date}`).format('YYYY-MM-DD') === dateHelpers('YYYY-MM-DD') )
        }
        else if (jobType === 'Up Coming Jobs') {
             return joblists.filter((e: any) =>{

                return moment(moment().format('DD-MMM-YYYY'), 'DD-MMM-YYYY').diff(moment(moment(e.booking_slot_date).format('DD-MMM-YYYY'), 'DD-MMM-YYYY')) < 0
             })
        } else if (jobType === 'Completed Jobs') {
            return joblists.filter((e: any) =>moment(moment().format('DD-MMM-YYYY'), 'DD-MMM-YYYY').diff(moment(moment(e.booking_slot_date).format('DD-MMM-YYYY'), 'DD-MMM-YYYY')) >0)
        } else if (bookingDate === "" && jobType === 'All Jobs') {
            return joblists
        } else {
            return joblists.filter((e: any) => moment(`${e.booking_slot_date}`).format('YYYY-MM') === moment(bookingDate).format('YYYY-MM'))
        }
    } catch (exception) {
        console.log(exception);
        throw exception;
    }


}