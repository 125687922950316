import { Grid, Card, CardContent, Typography } from "@mui/material";
import {  useSelector } from "react-redux";
import GreenTickIconSuccess from "../assets/images/GreenTickIconSuccess.svg";
import { RedirectionHelper } from "../helper/SignInPageHelpers"

export const SuccessPage = () => {
  const { status } = useSelector((state: any) => state.UsersStatus)
  return (
    <>
      {status.length > 0 ? RedirectionHelper(status[0].current_step, status[0].is_user_active_status) : null}
      <div className="Success_Page-container">
        <div className="center">
          <Grid item xs={6} >

            <div className="successPage-icon">
              <img className="icon" alt="" src={GreenTickIconSuccess} />
            </div>
            <Card className="successsPage-card">
              <CardContent>
                <div>
                  <Typography
                    variant="body2"
                    className="successPage-content"
                    fontSize={19}
                  >
                    Thank you for uploading the required documents, our team would
                    reach out to you shortly to verify your documents and activate
                    your profile
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </div>
      </div>
    </>
  );
}

