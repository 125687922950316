import { Divider, Grid } from "@mui/material"
import { Card } from "react-bootstrap"
import dollarIcon from "../../assets/images/dollarIcon.svg";
import houseIcon from "../../assets/images/houseIcon.svg";
import ovenIcon from "../../assets/images/ovenIcon.svg";
import officeBuilding from "../../assets/images/officeBuilding.svg";


export const DashboardEarningInformationComp = () => {
    return (
        <Grid container >
            <Grid item xs={12} mt={3}  >
                <Card className="payment">
                    <div className="payment_content">
                        <div className="payment_sectionFirst">
                            <h1 className="heading_payment">Your Payment  Information</h1>
                            <div className="icons">
                                <div className="icons_content">
                                    <img className="dollar" src={dollarIcon} />
                                </div>
                                <div className="icons_text">
                                    <div>Current Month Earnings</div>
                                    <div className="amount">$397.50</div>
                                </div>
                            </div>
                            <Divider className="payment_divider" variant="middle" />
                            <div className="icons_padding">
                                <div className="icons">
                                    <div className="icons_content">
                                        <img className="dollar" src={dollarIcon} />
                                    </div>
                                    <div className="icons_text">
                                        <div> Last Month Earnings</div>
                                        <div className="amount">$297.60</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider orientation="vertical" className="payment_devider" style={{ height: 'auto' }} />
                        <Divider orientation="vertical" className="devider-mobile-screen" />
                        <div className="payment_sectionSecond">
                            <h1 className="heading-top-earning">Your Top Earnings for Current Month</h1>
                            <div className="earning">
                                <div>
                                    <img className="earning_icons" src={houseIcon} />
                                </div>
                                <div className="earning_text">
                                    House Cleaning
                                </div>
                                <div className="earning_amount">$397.50</div>
                            </div>
                            <Divider variant="fullWidth" className="earning_divider" />

                            <div className="earning">
                                <div>
                                    <img className="earning_icons" src={ovenIcon} />
                                </div>
                                <div className="earning_text">
                                    Oven Cleaning
                                </div>
                                <div className="earning_amount">$397.50</div>

                            </div>
                            <Divider variant="fullWidth" className="earning_divider" />

                            <div className="earning">
                                <div>
                                    <img className="earning_icons" src={officeBuilding} />
                                </div>
                                <div className="earning_text">
                                    House Cleaning
                                </div>
                                <div className="earning_amount">$397.50</div>

                            </div>
                            <Divider variant="fullWidth" className="earning_divider" />

                            <div className="earning">
                                <div>
                                    <img className="earning_icons" src={ovenIcon} />
                                </div>
                                <div className="earning_text">
                                    Office Cleaning
                                </div>
                                <div className="earning_amount">$397.50</div>
                            </div>

                            <div className="payment__link" >Go to your all payments</div>
                        </div>
                    </div>
                </Card>
            </Grid>
        </Grid>
    )
}