import { useForm } from "react-hook-form";
import {
  Card,
  CardContent,
  Typography,
  Link,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast, ToastContainer } from "react-toastify";
import { ConfirmationForm } from "../forms/ConfirmationForm";

const errorMsg = "This field is required";
export const ConfirmationPage = () => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const {state}: any = history.location;

  const { email, number } = state;


  const onSubmit = async (data: any) => {
    try {
      const otpVerify = await Auth.confirmSignUp(email, data.otp);
      if (otpVerify) {
        history.push({ pathname: "/login", state: email });
      }
    } catch (exception) {
      toast.error(`${exception}`)
      console.log(exception);
      throw exception;
    }
  };

  const resendConfirmationCode = async () => {
    try {
      const data = await Auth.resendSignUp(email)
      toast.success("Code resend succesfully")
    } catch (exception) {
      toast.error(`${exception}`)
      console.log(exception);
      throw exception;
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="confirmation_page-container">
        <Card className="card">
          <CardContent>
            <Typography
              variant="h1"
              className="otp-heading"
              fontSize={32}
            >
              Verify Account!
            </Typography>
            <Typography
              variant="body2"
              className="otp-content"
              fontSize={12.5}
            >
              To verify your phone number, we've sent a One Time Password
              (OTP) to {number} (<Link>change</Link>)
            </Typography>
            <div className="form">
              <ConfirmationForm
              onSubmit={onSubmit}
              state={state}
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              />
            </div>
            <div className="link">
              <Link
                onClick={() => {
                  resendConfirmationCode();
                }}
              >
                Resend OTP
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </>

  );
}



