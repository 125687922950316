import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const GetChatMessageApi = async (bookingId: any): Promise<any> => {
    try {
        const config = {
            headers: {
                booking_id: bookingId,
                Authorization: await jwtToken()
            }
        }
        return await axios.get(`${envDevUrl}/GetCommunicationDataApi`, config)

    } catch (exception) {
        console.log(exception);
        throw exception;
    }
}