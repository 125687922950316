import { drawerSelectedDataType } from "../constants/action_type"

const initialState = {
    scheduleDrawerData: []
}

export const givenPostcodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case drawerSelectedDataType.CREATE_SCHEDULE_DRAWER_DATA:
            state = {
                ...state,
                scheduleDrawerData: [...state.scheduleDrawerData, action.payload] as any
            }
            break;
        case drawerSelectedDataType.GET_SCHEDULE_DRAWER_DATA:
            state = {
                ...state,
                scheduleDrawerData: action.payload
            }
            break;
        case drawerSelectedDataType.DELETE_SCHEDULE_DRAWER_DATA:
            state = {
                ...state,
                scheduleDrawerData: state.scheduleDrawerData.filter((user: any) =>
                (user.worksman_schedule_date != action.payload.worksman_schedule_date ||
                    user.worksman_schedule_time != action.payload.worksman_schedule_time))
            }
            break;
    }
    return state
}