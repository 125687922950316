import { Button, Grid } from "@mui/material";
import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { ModalBoxComp } from "../CommonComponents/ModalBoxComp";
import { ProofOfIdentityComp } from "./ProofOfIdentityComp";

// S3 buckets
import { s3bucketConfiguration } from "../../constants/S3BucketConstant";
const reactS3 = require("react-s3");


export const DocumentsComp = ({register, errors}: any) => {

  const [residentDetails, setresidentDetails] = useState()
  const [serviceDetails, setServiceDetails] = useState()
  const [bankDetails, setBankDetails] = useState()

  const [modalClose, setModalClose] = useState(false);

  const proofOfResidenceInput = async (e: any) => {
    let file = e.target.files[0]
    const responseUplodResponse = await reactS3.uploadFile("index.svg", s3bucketConfiguration);
    };
  return (

    <div className="experience_content">
      <div className="experience_content_elements">
        <div className="experience_content_text">
          <Grid xs={12} item className="document_line_height">
            <h2 className="">Documents:</h2>
            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"center"}
              spacing={2}
              className="documents_section"
            >
              <Grid xs={6} item>
                <p>Proof of Residence:<span className="help_text" onClick={() =>
                  setModalClose(true)
                }><InfoIcon className="help_icon" /></span></p>
              </Grid>
              <Grid xs={6} item className="document_btn_content" >

                <input
                  type="file"
                  id="residence_proof"
                  style={{ display: "none" }}
                  accept={".pdf,.svg,image/jpeg"}
                  {...register('resident', { required: true })}
                  onChange={(e: any) => {
                    setresidentDetails(e.target.files[0].name)
                    proofOfResidenceInput(e)
                  }}

                />
                <label htmlFor="residence_proof">
                  <Button
                    className="btn-document_section"
                    component="span"
                    variant="contained"
                  >
                    Upload
                  </Button>{" "}
                  <p className="file_name">{residentDetails}</p>
                </label>
                <ModalBoxComp
                  show={modalClose}
                  onClose={() => setModalClose(false)}
                  title={"Proof of Identity"}
                  children={
                    <div>
                      <ProofOfIdentityComp />
                    </div>
                  }
                />
              </Grid>

              <Grid xs={6} item>
                <p>Service ID:<span className="help_text" onClick={() =>
                  setModalClose(true)
                }><InfoIcon className="help_icon" /></span></p>
              </Grid>
              <Grid xs={6} item className="document_btn_content" >
                <input
                  type="file"
                  id="bankdetils"
                  style={{ display: "none" }}
                  accept={".pdf,.svg,image/jpeg"}
                  {...register('bank_details', { required: true })}
                  onChange={(e: any) => {
                    setServiceDetails(e.target.files[0].name)
                    proofOfResidenceInput(e)
                  }}

                />
                <label htmlFor="bankdetils">
                  <Button
                    className="btn-document_section"
                    component="span"
                    variant="contained"
                  >
                    Upload
                  </Button>{" "}
                  <p className="file_name" >{serviceDetails}</p>
                </label>
                <ModalBoxComp
                  show={modalClose}
                  onClose={() =>
                    setModalClose(false)
                  }
                  title={"Proof of Identity"}
                  children={
                    <div>
                      <ProofOfIdentityComp />
                    </div>
                  }
                />
              </Grid>

              <Grid xs={6} item>
                <p>Bank Proof:<span className="help_text" onClick={() =>
                  setModalClose(true)
                }><InfoIcon className="help_icon" /></span></p>
              </Grid>
              <Grid xs={6} item className="document_btn_content" >

                <input
                  type="file"
                  id="service_id"
                  style={{ display: "none" }}
                  accept={".pdf,.svg,image/jpeg"}
                  {...register('service', { required: true })}
                  onChange={(e: any) => {
                    setBankDetails(e.target.files[0].name)
                    proofOfResidenceInput(e)
                  }}

                />
                <label htmlFor="service_id">
                  <Button
                    className="btn-document_section"
                    component="span"
                    variant="contained"
                  >
                    Upload
                  </Button>{" "}
                  <p className="file_name" >{bankDetails}</p>
                </label>
                <ModalBoxComp
                  show={modalClose}
                  onClose={() => setModalClose(false)
                  }
                  title={"Proof of Identity"}
                  children={
                    <div>
                      <ProofOfIdentityComp />
                    </div>
                  }
                />
              </Grid>
            </Grid>
            {(errors.resident || errors.bank_details || errors.service) && (
              <div style={{ textAlign: 'right', marginTop: 20 }} >
                <span className="errors" >Please upload all documents</span>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};
