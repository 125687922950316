import { Grid, CardContent, Button, IconButton } from "@mui/material";
import plusIcon from "../../assets/images/plusIcon.svg";
import { useState } from "react";


export const ProofOfIdentityComp = ({ }: any) => {

  return (
    <>
      <div>
        <Grid sm={12} xs={12} lg={12} xl={12} item>
          <div className="ProofOfIdentity-container">
            <CardContent>
              <div className="documents">
                <div className="subcontent">
                  <h5>Document Must:</h5>
                  <ul>
                    <li className="list">Be valid and not Expired</li>
                    <li className="list">Be in pdf format</li>
                    <li className="list">Size should not be more tha 2MB</li>
                  </ul>
                </div>
              </div>
              {/* <Grid className="upload_Section">
                <Grid item style={{ textAlign: "center" }}>
                  <input
                    type="file"
                    id="residence-proof"
                    style={{ display: "none" }}
                    onChange={proofOfResidenceInput}
                    // {...register("residenceProof", { required: true })}
                  />
                  <label htmlFor="residence-proof">
                    <IconButton className="IconButton" component="span">
                      <img src={plusIcon} alt="" className="icon" />
                    </IconButton>
                  </label>
                </Grid>
                <div className="proof">
                  <p style={{ fontSize: 17, fontWeight: "bold" }}>
                    Proof of Residence
                  </p>
                  <p style={{ marginTop: -20, fontSize: 13.5 }}>
                    Upload document here
                  </p>
                </div>
              </Grid>
              {error ? <div className="errors">Please choose file</div> : ""} */}
              {/* <div>
                <Button
                  className="button"
                  variant="contained"
                  type="submit"
                  onClick={() => closeModal()}
                >
                  Confirm
                </Button>
              </div> */}
            </CardContent>
          </div>
        </Grid>
      </div>
    </>
  );
};
