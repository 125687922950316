import { Card, IconButton } from '@mui/material';
import { Auth } from 'aws-amplify';
import { toast, ToastContainer } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SetNewPasswordForm } from '../forms/SetNewPasswordForm';
import { useForm } from 'react-hook-form';

export const SetNewPasssord = (props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    const history = useHistory()
    const { email } = props.location.state
    

    const changePassword = async (data) => {
        const { otp, password, re_enter_password } = data
        try {
            if (password !== re_enter_password) {
                toast.error('Password and Re-Enter password not match')
            } else {
                const data = await Auth.forgotPasswordSubmit(email, otp, password)
                if (data) {
                    props.history.push('/login')
                }
            }

        } catch (exception) {
            toast.error(`${exception}`)
            console.log(exception);
            throw exception;
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='set_new_password common-styles'>
                <div>
                    <div className="commom_back_icon" onClick={() => history.goBack()}>
                        <IconButton className="icon_button">
                            <ArrowBackIosIcon className="icon_button__arrow-icon" />
                            Back
                        </IconButton>
                    </div>
                    <Card className='commom_card'>
                        <h2>Set new password</h2>
                        <div className='common_info_container'>
                            <div className='common_info_container__content'>{email}</div>
                            <div><KeyboardArrowDownIcon /></div>
                        </div>
                        <SetNewPasswordForm 
                        register={register}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        changePassword={changePassword} />
                    </Card>
                </div>
            </div>
        </>
    )
}
