
// React related data
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { BankDetailForm } from "../../forms/BankDetailForm";

// redux dispatch Actions
import { GetBankDetailsAction } from "../../actions/bankDetailsAction";
import { updateBankDetailsApi } from "../../api/updateBankDetailsApi";

// apis
import { GetBankDetailsApi } from "../../api/BankDetailsApi";

export const EidtBankDetailsComp = () => {

  // constants
  const dispatch = useDispatch()
  const history = useHistory()
  const { status } = useSelector((state: any) => state.UsersStatus)
  const { state }: any = history.location
  const { account_name, sort_code, account_number, bank_name }: any = state[0]
  const { bank_details } = useSelector((state: any) => state.allBanksDetails)

  // Get bank details
  const getBankDetails = async (worksmanId: any) => {
    const { data } = await GetBankDetailsApi(worksmanId);
    dispatch(GetBankDetailsAction(JSON.parse(data.data).body));
  };

  // Update bank details
  const updateBankDetails = async (data: any) => {
    try {
      const updateBankDetails = await updateBankDetailsApi(data, status, state)
      getBankDetails(status[0].worksman_id);
      toast.success("Bank details updated successfull")
      history.goBack()
    } catch (exception) {
      throw exception
    }
  }

  return (
    <div className="bankDetailsSave_container">
      <BankDetailForm
        updateBankDetails={updateBankDetails}
        bankDetail={state[0]}
      />
    </div>
  );
};
