import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BannerComp } from "../components/LandingPageComponents/BannerComp";
import { InNewsComp } from "../components/LandingPageComponents/InNewsComp";
import { TestimonialComp } from "../components/LandingPageComponents/TestimonialComp";
import { FooterBarComp } from "../components/LayoutComponents/FooterBarComp";
import { RedirectionHelper } from "../helper/SignInPageHelpers";

export const LandingPages = () => {
  const { status } = useSelector((state: any) => state.UsersStatus)
  const history = useHistory();
  return (
    <>
      {status.length > 0 ? RedirectionHelper(status[0].current_step, status[0].is_user_active_status) : null}
      <div className="landing-page common-styles">
        <BannerComp
          history={history}
        />
        <InNewsComp />
        <TestimonialComp />
      </div>

      <FooterBarComp />
    </>
  );


};
