import axios from "axios";
import { envDevUrl } from "../constants/allConstants";
import { jwtToken } from "../constants/jwtToken";

export const ExperiencePageApi = async (formData: any, id: any): Promise<any> => {

    try {
        const data = {
            worksman_id: id,
            years_of_cleaning_exp: parseInt(formData.experience),
            Have_end_of_tendency: formData.end_of_tendency,
            Have_hotel_cleaning: formData.hotel_cleaning,
            Have_house_cleaning: formData.house_cleaning,
            Have_hospital_cleaning: formData.hospital_cleaning,
            Have_office_cleaning: formData.office_cleaning,
            Have_other_cleaning: formData.other_cleaning,
            Have_Right_to_Work: formData.right_to_work_uk,
            Have_UK_BankAccount: formData.no_criminal_records,
            Have_Criminal_Records: formData.have_bank_account
        }

        const config = {
            headers: {
                Authorization: await jwtToken()
            }
        }
        return await axios.post(`${envDevUrl}/worksmanExperienceapi`, data, config);
    } catch (exception) {
        console.log(exception);
        throw exception;
    }
}