import { useDispatch, useSelector } from "react-redux";
import { DashboardCurrentJobsComp } from "../components/DashboardPageComponents/DashboardCurrentJobsComp";
import { DashboardInformationComp } from "../components/DashboardPageComponents/DashboardInformationComp";
import { DashboardEarningInformationComp } from "../components/DashboardPageComponents/DashboardEarningInformationComp";
import { DashboardHelpSectionComp } from "../components/DashboardPageComponents/DashboardHelpSectionComp";
import { useState } from "react";
import { getSingleUserDataAction } from "../actions/SingleUserDataAction";
import { useHistory } from "react-router-dom";

export const WorksmanInformationComp = () => {

  const [open, setOpen] = useState(false);

  const history = useHistory()
  const dispatch = useDispatch()

  const { useDetails } = useSelector((state: any) => state.userDetails.userDetails)
  const { joblists } = useSelector((state: any) => state.joblistingreducer)


  const handleDrawerOpen = (custoermData: any) => {
    setOpen(true);
    dispatch(getSingleUserDataAction(custoermData))
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="dashBoard_container">
      <h1 className="main_heading">Welcome, {useDetails !== undefined ? `${useDetails[0].first_name} ${useDetails[0].last_name}` : ''}</h1>
      <div className="subheading">
        Information about you and your profile at Vrishkar Services
      </div>
      <DashboardCurrentJobsComp
        joblists={joblists}
        handleDrawerOpen={handleDrawerOpen}
        setOpen={setOpen}
        open={open}
      />
      <DashboardEarningInformationComp />
      <DashboardInformationComp />
      <DashboardHelpSectionComp />
    </div>
  );
};
