import axios from "axios";
import { envDevUrl } from '../constants/allConstants';
import { jwtToken } from "../constants/jwtToken"
export const termsAndConditionsApi = async (data: any, id: any): Promise<any> => {

  try {
    const postData = {
      worksman_id: id,
      isWatched_howVrishkar_Works: `${data.eligibilty}`,
      isUnderstand_Worksman_Will_SelfEmployeed: `${data.eligibilty1}`,
      isUnderstand_Vrishkar_isnot_responsible: `${data.eligibilty2}`,
    }
    const config = {
      headers: {
        Authorization: await jwtToken()
      }
    }
    const termsAndCondition = await axios.post(
      `${envDevUrl}/WorksmanTermsAndConditionsApi`, postData, config
    );
    return termsAndCondition
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
};
