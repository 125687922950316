
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Typography } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { WorkmanAndCustomerChatForm } from '../../forms/WorkmanAndCustomerChatForm';

export const CustomerAndWorkmanChatComp = ({ messages,getMessages,user }: any) => {
   console.log("messagesmessages",messages)
    useEffect(()=>{
        getMessages(user.booking_id)
    },[])

    return (
        <>
            <div className="drawer_container__divider" style={{ display: 'flex', alignItems: 'center', marginTop: 10 }} >
                <div style={{ fontSize: '10px', marginRight: 6 }}>
                    <span>{messages.length <= 9 ? messages.length : '9+'} Replies</span>
                </div>
                <div style={{ borderBottom: '1px solid #ccc', width: '80%' }} />
            </div>


            <div className="drawer_container__chattings">
                <Typography variant='body1' textAlign={'left'} >Chat headers</Typography>
                {messages.length === 0 && <p>No Messages here</p>}
                {messages.length !== 0 &&
                    <div className="chat_content_box">
                        {
                            messages.filter((e:any)=>e.booking_id===user.booking_id).map((chatting: any, index: any) => {
                                return <div className="chat_content" key={index}>
                                    <div className="chat_content__icon"> <AccountBoxIcon className="" style={{ color: '#000' }} fontSize="large" /></div>
                                    <div className="chat_content__mesage-content">
                                        <div className="chat_content__mesage-content__name-hour">
                                            <div className="chat_content__mesage-content__name-hour__name"> 
                                            {chatting.first_name == null ? chatting.fsname : chatting.fsname == null && chatting.first_name} {chatting.last_name}
                                            </div>
                                            <div className="chat_content__mesage-content__name-hour__time">
                                                {
                                                    moment(`${chatting.created_on}`).format('lll') !== 'Invalid date' ?
                                                        moment(`${chatting.created_on}`).format('lll') : moment().format('lll')}
                                            </div>
                                        </div>
                                        <div className="chat_content__mesage-content__mesage">
                                            {chatting.notes}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                }
                <WorkmanAndCustomerChatForm user={user} getMessages={getMessages}/>
            </div>
        </>
    )
}