// UI COMPONENTS
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";

// assets
import landingPageImage from "../../assets/images/girl-img.png";
import banner from "../../assets/images/banner.svg";
import StarsX from "../../assets/images/StarsX.svg";
import StarsY from "../../assets/images/StarsY.svg";
// JSON Data
import {
  startCleanerOne,
  startCleanerTwo,
} from "../../constants/starts_cleaner_content";
import { signUp } from "../../routersNames";

export const BannerComp = ({ history }: any) => {
  return (
    <div>
      <div className="landing-page-img">
        <img src={landingPageImage} alt="" />
      </div>
      <div className="landing-page__desktop-container">
        <Grid
          container
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid md={7} sm={7} item>
            <div className="starsY">
              <img
                className="starsY"
                src={StarsY}
                alt=""
                style={{ width: 42, height: 42, marginLeft: -20 }}
              />
            </div>
            <h2 className="heading" style={{ marginTop: 30, fontSize: 35 }}>
              Starts as Cleaner
            </h2>
            <p className="landing-page__sub-heading">
              Flexible working opportunites and support can benefit everyone!
            </p>
            <Grid container justifyContent={"space-between"} item md={12}>
              <Grid item xs={12} sm={6}>
                {startCleanerOne.map((item: any, indx) => (
                  <div className="landing-page-icon" key={indx}>
                    <div className="landing-page-icon__img">
                      {" "}
                      <img src={item.img} alt="" />
                    </div>
                    <div className="landing-page-icon__text">{item.text}</div>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12} sm={6}>
                {startCleanerTwo.map((item, indx) => (
                  <div className="landing-page-icon" key={indx}>
                    <div className="landing-page-icon__img">
                      {" "}
                      <img src={item.img} width="20" alt="" />
                    </div>
                    <div className="landing-page-icon__text">{item.text}</div>
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid className="btn-container banner-btn" xs={12} item>
              <Button
                className="btn-join"
                onClick={() => {
                  history.push(signUp);
                }}
              >
                Join Us
              </Button>
              <img
                className="starsX"
                src={StarsX}
                alt=""
                style={{ width: 42, height: 42, marginLeft: 50 }}
              />
            </Grid>
          </Grid>
          <Grid
            md={5}
            sm={5}
            item
            className="landing-img-container mobile-screen"
          >
            <img src={banner} alt="" className="landing-img" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
