import { postCodeActionTypes } from "../constants/action_type";

const initialStatus = {
    postcodes: []
}

export const userPostcodesReducer = (state = initialStatus, action: any) => {
    switch (action.type) {
        case postCodeActionTypes.GET_POST_CODE:
            state = {
                ...state,
                postcodes: action.payload
            }
            break;
    }
    return state
}