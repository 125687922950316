import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { termsAndConditionsApi } from "../api/termsAndConditionApi";
import { TermsAndConditionCommon } from "../components/Customs/TermsAndConditionCommon";
import { TermsAndConditionForm } from "../forms/TermsAndConditionForm";
import { UserExperience } from "../routersNames";

export const TermsAndConditionPage = () => {

  // CONSTANTS
  const history = useHistory();
  const { status } = useSelector((state: any) => state.UsersStatus)
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data: any) => {
    try {
      if (status.length !== 0) {
        const responseData: any = await termsAndConditionsApi(data, status[0].worksman_id);

        // console.log("responseData",JSON.parse(JSON.parse(JSON.stringify(responseData.data))))
        console.log("responseData",responseData)
        if (responseData.status === 200) {
          history.push({
            pathname: UserExperience,
            state: history.location.state,
          });
        }
      }
    } catch (exception) {
      console.log(exception);
      throw exception;
    }
  };

  return (
    <Grid
      container
      justifyContent="space-around"
      className="TermsConditionPage_container common-styles "
    >
      <Grid className="Terms_Condition_Sub_Container">
        <TermsAndConditionCommon />
        <hr />
        <TermsAndConditionForm
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};

