import { Grid } from "@mui/material";
import Cleaner from "../assets/images/Cleaner.svg";
import CarImage from "../assets/images/CarImage.svg";
import { UserRegistrationForm } from "../forms/UserRegistrationForm";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getAllMobileNumbersApi } from "../api/getAllMobileNumbersApi";
import { emailRegex } from "../constants/RegExp";
import { userRegistrationAddressApi } from "../api/userRegistrationAddressApi";
import { userRegistrationHelpers } from "../helper/userRegistrationHelpers";

export const UserRegistrationPage = (props: any) => {

  // States
  const history = useHistory();

  const [emailError, setEmailError] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(true);
  const [/*confirmPassword*/, setConfirmPassword] = useState(false);
  const [houseNum, setHouseNum] = useState("");
  const [postcodes, setPostCode] = useState("");
  const [apiAddresData, setApiAddresData] = useState([]);
  const [addressApiError, setAddressApiError] = useState(false);
  const [apiPostCode, setApiPostCode] = useState();
  const [showBox, setShowBox] = useState(true);
  const [addSelect, setAddSelect] = useState([]);
  const [boxs, setBoxs] = useState(true);
  const [error, setErros] = useState(false);
  const [addStat, setAddStat] = useState(false);
  const [singleAddCard, setSingleAddCard] = useState(true);
  const [multiAdd, setMultiAdd] = useState(true);
  const [mobileNumber, setMobileNumber] = useState([]);
  const [newAddress, setNewAddress] = useState(false);
  const [newAdd, setNewAdd] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onChangeHandler = (event: any) => {
    let val = event.target.value;
    if (val.match(emailRegex)) {
      setEmailError(false);
      setEmailSuccess(false);
    } else {
      setEmailSuccess(true);
      setEmailError(true);
    }
  };

  useEffect(() => {
    const getMobielNumber = async () => {
      const { data }: any = getAllMobileNumbersApi()
      let { body } = JSON.parse(data.data);
      setMobileNumber(body);
    };
    getMobielNumber();
  }, []);

  // Functions
  const Address = async () => {
    try {
      const { data } = await userRegistrationAddressApi(postcodes, houseNum);
      const { addresses, postcode } = data;
      setApiAddresData(addresses);
      setApiPostCode(postcode);
      if (addresses.length > 0) {
        setAddressApiError(false);
        setAddStat(true);
        setShowBox(false);
        if (!boxs) {
          setBoxs(true);
        }
        if (singleAddCard === false) {
          setSingleAddCard(true);
        }
        if (multiAdd === false) {
          setMultiAdd(true);
        }
      } else if (addresses.length === 0) {
        console.log(
          "Unfortunately, we are returning too many matches for this address. Please refine your search, or Enter manually"
        );
        setNewAddress(true);
        setAddressApiError(false);
      } else {
        setAddressApiError(true);
      }
    } catch (exception) {
      setShowBox(true);
      setAddressApiError(true);
      if (exception) {
        setAddressApiError(true);
      }
      console.log(exception);
      throw exception;

    }
  };

  const onSubmit = async (data: any) => {
    const mobileNumVal = mobileNumber.some((e: any) => e.phone === data.number);
    try {
      if (data.password !== data.confirmPassword) {
        toast.warning(` Confirm password didn't match to password`);
      }
      else if (!addStat) {
        setErros(true);
        setConfirmPassword(false);
      } else if (mobileNumVal === true) {
        setErros(false);
        setConfirmPassword(false);
        toast.error("Mobile number already existed");
      } else {
        setErros(false);
        setConfirmPassword(false);
        await userRegistrationHelpers(
          apiAddresData,
          addSelect,
          data,
          history,
          apiPostCode
        );
      }
    } catch (exception) {
      toast.error(`${exception}`);
    }
  };

  return (
    <div className="UserRegistration-container">
      <Grid container direction="row" justifyContent="space-around">
        <ToastContainer className="toast-container" />
        <Grid item sm={5} xs={12} md={5} lg={5} xl={5}>
          <h1 className="form-heading">Create your Account</h1>
          <p className="form-p">
            Please fill out the following form to create your account @ Vrishkar
          </p>
          <UserRegistrationForm
            emailError={emailError}
            emailSuccess={emailSuccess}
            addressApiError={addressApiError}
            showBox={showBox}
            error={error}
            newAddress={newAddress}
            newAdd={newAdd}
            setNewAdd={setNewAdd}
            setAddSelect={setAddSelect}
            setHouseNum={setHouseNum}
            setPostCode={setPostCode}
            onSubmit={onSubmit}
            Address={Address}
            onChangeHandler={onChangeHandler}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            houseNum={houseNum}
            postcodes={postcodes}
            setAddStat={setAddStat}
            apiAddresData={apiAddresData}
            boxs={boxs}
            addSelect={addSelect}
            setBoxs={setBoxs}
            singleAddCard={singleAddCard}
            apiPostCode={apiPostCode}
            setShowBox={setShowBox}
            setSingleAddCard={setSingleAddCard}
            setApiAddresData={setApiAddresData}
            multiAdd={multiAdd}
            setMultiAdd={setMultiAdd}
          />
        </Grid>
        <Grid item sm={7} md={7} lg={7} xl={7} className="image-grid">
          <div className="image-container">
            <img className="form-img" alt="" src={Cleaner} />
            <img className="car-img" alt="" src={CarImage} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
