import axios from "axios"
import moment from "moment"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const GetWorksmanScheduleApi = async (worksmanId: any, date: any): Promise<any> => {
  console.log("datadatadata", moment(date).format('DD MMM YYYY'))
  try {
    const config = {
      headers: {
        worksman_id: worksmanId,
        schedule_date: moment(date).format('DD MMM YYYY'),
        Authorization: await jwtToken()
      }
    }
    return await axios.get(`${envDevUrl}/GetWorksmanScheduleApi`, config)
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
}
