import { Route, Switch } from 'react-router-dom';
import { SigninPage } from './basmodule';
import { confirmation_page, forgotPassword, landing_page, login, recovre_password, signUp } from './routersNames';
import {
    LandingPages,
    UserRegistrationPage, ConfirmationPage,
    AccountRecoveryPage as ForgotPassword,
    SetNewPasssord as RecoveryPassword
} from './basmodule';

export const PublicRoutes = () => {
    return (
        <Switch>
            <Route exact path={landing_page} component={LandingPages} />
            <Route exact path={signUp} component={UserRegistrationPage} />
            <Route exact path={confirmation_page} component={ConfirmationPage} />
            <Route exact path={login} component={SigninPage} />
            <Route exact path={forgotPassword} component={ForgotPassword} />
            <Route exact path={recovre_password} component={RecoveryPassword} />
        </Switch>
    )
}
