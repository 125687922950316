import { TextField, Link, Stack, Button } from "@mui/material";
import { PasswordTextFieldComp } from "../components/CommonComponents/PasswordTextFieldComp";
import { errorMsg } from "../constants/errorMsg";
import { forgotPassword, signUp } from "../routersNames";
import { RedirectionHelper } from "../helper/SignInPageHelpers";

export const SignInForm = ({ LoginUser, btn, register, handleSubmit, errors, status }: any) => {
    return (
        <>
            {status.length > 0 ? RedirectionHelper(status[0].current_step, status[0].is_user_active_status) : null}
            <form onSubmit={handleSubmit(LoginUser)}>
                <div className="SigninPage">
                    <h1 className="SigninPage-heading">Sign In</h1>
                    <div className="textField">
                        <TextField
                            fullWidth
                            label="Email/Phone No"
                            type="email"
                            className="text-field"
                            {...register("emailId", { required: true })}
                        />
                        {errors.emailId && (
                            <span className="errors">This field is required</span>
                        )}
                        <PasswordTextFieldComp
                            register={register}
                            label='Password'
                            errormsgCond={errors.password}
                            name='password'
                            required={errorMsg}
                        />
                    </div>

                    <div className="" style={{ display: 'flex', justifyContent: "space-between" }}>
                        <div className="link" onClick={() => window.location.href = forgotPassword}>
                            <Link style={{ color: "#000000", cursor: 'pointer' }}  >Forgot Password?</Link>
                        </div>
                        <div className="link" onClick={() => window.location.href = signUp}>
                            <Link style={{ color: "#000000", cursor: 'pointer' }}  >Register</Link>
                        </div>
                    </div>
                    <Stack direction="row">
                        <Button variant="contained" className="common_button" type="submit" disabled={btn}>
                            Login
                        </Button>
                    </Stack>
                </div>
            </form>
        </>
    );
};
