import axios from "axios";
import { envDevUrl } from "../constants/allConstants";
import { jwtToken } from "../constants/jwtToken";

export const getAllPostcodesApi = async (worksman_id: any): Promise<any> => {
    try {
        const config = {
            headers: {
                worksman_id: worksman_id,
                Authorization: await jwtToken()
            }
        }
        return await axios.get(`${envDevUrl}/GetAllPostcodesApi`, config);

    } catch (exception) {
        console.log(exception);
        throw exception;
    }
};