import { Divider, Grid } from "@mui/material"
import { Card } from "react-bootstrap"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import calenderIcon from "../../assets/images/calenderIcon.png";
import postCodeIcon from "../../assets/images/postCodeIcon.png";
import { post_codes, userScheduleJob } from "../../routersNames";

export const DashboardInformationComp = ({history}:any) => {
      return (
        <Grid container className="postcodes" justifyContent={'space-between'} mt={1} spacing={5}  >
            <Grid item xs={12} sm={12} md={6}>
                <Card className="your_scheduled_jobs">
                    <div className="your_scheduled_jobs_content">
                        <h1 className="your_scheduled_jobs_heading">
                            Your Scheduled Jobs
                        </h1>
                        <img src={calenderIcon} className="your_scheduled_jobs_image" />
                    </div>
                    <div className="your_scheduled_jobs_text">
                        Schedule your jobs here, manage your jobs and start working as per your schedule
                    </div>
                    <Divider className="divider" />
                    <p className="your_scheduled_jobs_manageJobs" onClick={() => history.push(userScheduleJob)} >
                        Manange schedule jobs here
                    </p>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} >
                <Card className="your_scheduled_jobs">
                    <div className="your_scheduled_jobs_content">
                        <h1 className="your_scheduled_jobs_heading">
                            Your Available Post Codes
                        </h1>
                        <img src={postCodeIcon} className="your_scheduled_jobs_image" />
                    </div>
                    <div className="your_scheduled_jobs_text">
                        If you don't have any post code add here and you can mannage your post codes here
                    </div>
                    <Divider className="divider" />
                    <p className="your_scheduled_jobs_manageJobs" onClick={() => history.push(post_codes)}>
                        Manange your post codes here
                    </p>
                </Card>
            </Grid>
        </Grid>
    )
}