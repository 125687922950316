import { Button, TextField } from '@mui/material';
import { errorMsg, strongPasswordErroMsg } from '../constants/errorMsg';
import { passwordRegex } from '../constants/RegExp';
import { PasswordTextFieldComp } from '../components/CommonComponents/PasswordTextFieldComp';

export const SetNewPasswordForm = ({ changePassword, register, errors, handleSubmit }: any) => {
    return (
        <form onSubmit={handleSubmit(changePassword)} >
            <div className='set-password_text-field'>
                <TextField
                    fullWidth
                    label='OTP'
                    className='text-field'
                    {...register('otp', { required: errorMsg })}
                />
                <div className='error_msg'>
                    {errors.otp && <span>{errors.otp.message}</span>}
                </div>
            </div>
            <div className='set-password_text-field'>
                <PasswordTextFieldComp
                    register={register}
                    label='Renter passowrd'
                    errormsgCond={errors.re_enter_password}
                    name='re_enter_password'
                    pattern={{ value: passwordRegex, message: strongPasswordErroMsg }}
                    required={errorMsg}
                />
            </div>

            <div className='set-password_text-field'>
                <PasswordTextFieldComp
                    register={register}
                    label='New passowrd'
                    errormsgCond={errors.password}
                    name='password'
                    pattern={{ value: passwordRegex, message: strongPasswordErroMsg }}
                    required={errorMsg}
                />
            </div>
            <div>
                <Button className='common_button' type='submit'>
                    save
                </Button>
            </div>
        </form>
    )
}