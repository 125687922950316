import { Divider } from "@mui/material"

export const JobDetailsListDividerComp = ({ divider, ...children }: any) => {
    return (
        <>
            <div className="drawer_container__details" {...children} />

            {divider && (
                <div className="drawer_container__divider">
                    <Divider />
                </div>
            )}
        </>
    )
}