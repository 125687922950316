import { postCodeActionTypes } from "../constants/action_type";


// GET ACTION

export const GetPostCode = (getPostcodes: any) => {
    return {
        type: postCodeActionTypes.GET_POST_CODE,
        payload: getPostcodes as any,
    };
};

;
