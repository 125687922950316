
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from 'moment';
import { jobs } from '../../constants/allConstants';
import { dateHelpers, subStrDateHelpers } from '../../helper/dateHelpers';

export const FilterDatesAndSelectComp = ({
    setJobType,
    jobType,
    jobDetailFilter,
    bookingDate,
    setBookingDate
}: any) => {

    const [selectedMonth, onChangeMonth, setPreviousMonth, setCurrentMonth, setOldMonth,
        setFutureMonth, monthsWithYear, currentMonth] = jobDetailFilter
    return (
        <div className="job_listing__date-with-filter" >
            <Grid container >
                <Grid xs={12} item md={8} sm={12} >
                    <div className="job_listing__months-year">
                        <div className="job_listing__months-year__heading"> Your Assigned Bookings </div>
                        <div className="job_listing__months-year__content">
                            <div>
                                <IconButton
                                    onClick={() => {
                                        setOldMonth(pre => pre + 4)
                                        setCurrentMonth(pre => pre + 4)
                                        setPreviousMonth(pre => pre + 4)
                                        setFutureMonth(pre => pre + 4)
                                    }}
                                >
                                    <ArrowBackIosNewIcon className="job_listing__months-year__content__left-arrow" />
                                </IconButton>
                            </div>

                            {monthsWithYear.map((monthandyear: any, index: any) => {
                                let month = subStrDateHelpers('YYYY-MMM', monthandyear, 'month', 'YYYY-MM')
                              
                                return <div key={index}                                 
                                className={`job_listing__months-year__content__boxes
                               
                                ${ moment(bookingDate).format('YYYY-MM') === month
                                        ? 'date-color' : ""} `}
                                    onClick={() => {
                                        setBookingDate(month)
                                        setJobType("")
                                        selectedMonth(monthandyear)
                                    }}
                                >
                                    {subStrDateHelpers('YYYY-MMM', monthandyear, 'month', 'MMM')}<br />
                                    {subStrDateHelpers('YYYY-MMM', monthandyear, 'month', 'YYYY')}
                                </div>
                            })}

                            <div className="">
                                <IconButton
                                    onClick={() => {
                                        setCurrentMonth(pre => pre - 4)
                                        setOldMonth(pre => pre - 4)
                                        setPreviousMonth(pre => pre - 4)
                                        setFutureMonth(pre => pre - 4)
                                    }}
                                    disabled={dateHelpers('MM') === dateHelpers(currentMonth) && dateHelpers('YYYY') === dateHelpers(currentMonth) ? true : false}
                                >
                                    <ArrowForwardIosIcon className="job_listing__months-year__content__right-arrow" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12} item md={4} sm={12} >
                    <div className="job_listing__months-year__heading" style={{ visibility: 'hidden' }}> Your Assigned Bookings  </div>
                    <div className="job_listing__all-jobs">
                        <FormControl fullWidth>
                            <InputLabel>Jobs</InputLabel>
                            <Select
                                value={jobType ? jobType : ""}
                                label="Jobs"
                                onChange={(e: any) => {
                                    setJobType(e.target.value)
                                    // setShowMonth("")
                                    setBookingDate("")
                                    onChangeMonth()
                                }}
                                displayEmpty
                            >

                                {jobs.map((e, index: any) => {
                                    return <MenuItem value={e} key={index}>{e}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}