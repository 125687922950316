// assets
import successIcon from "../assets/images/successIcon.svg";
import numberIcon from "../assets/images/numberIcon.svg";

// Form
import { ExperienceForm } from "../forms/ExperienceForm";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ExperiencePageApi } from "../api/ExperiencePageApi";
import { success } from "../routersNames";

export const ExperiencePage = () => {

  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [checkboxError, setCheckboxError] = useState(false)
  const { status } = useSelector((state: any) => state.UsersStatus)


  // Functions
  const experiencePageSubmit = async (data: any) => {
    console.log("This is experience", data,typeof(parseInt(data.experience)))

    const { end_of_tendency, hotel_cleaning, house_cleaning, hospital_cleaning, office_cleaning,
      other_cleaning } = data

    const obj = [end_of_tendency, hotel_cleaning, house_cleaning, hospital_cleaning, office_cleaning,
      other_cleaning]

    let experieceInDifferentField = obj.filter((e: any) => e === true)
    try {
      if (experieceInDifferentField.length >= 3) {
        setCheckboxError(false)
        if (status.length !== 0) {
          const expData = await ExperiencePageApi(
            data,
            status[0].worksman_id
          );
          console.log("expDataexpData", expData)
          if (expData.status === 200) {
            history.push(success);
          }
        }

      } else {
        setCheckboxError(true)
      }

    } catch (exception) {
      console.log(exception)
      throw exception
    }
  };
  return (
    <>
      <div className="experience_container common-styles" >
        <div>
          <div className="experience_content">
            <div className="experience_content_elements">
              <span className="sucess_icon">
                <img className="icon" alt="" src={successIcon} />
              </span>
              <img className="icon" alt="" src={numberIcon} />
            </div>
          </div>
          <ExperienceForm
            experiencePageSubmit={experiencePageSubmit}
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            checkboxError={checkboxError}
          />
        </div>
      </div>
    </>
  );
};


