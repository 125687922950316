import axios from "axios"
import { getAddressUrl } from "../constants/allConstants";

export const userRegistrationAddressApi = async (postcodes: any, houseNum: any): Promise<any> => {
    try {
        // -nOfbVJpVUaxAaJZyNvjpw33856
        return await axios.get(`${getAddressUrl}/${postcodes}/${houseNum}?api-key=FAI4a7aEaE2tPLkCwIOssA35048&expand=true`)
    } catch (exception) {
        console.log(exception);
        throw exception;
    }
}
