import { Auth } from "aws-amplify";
import axios from "axios";
import { envDevUrl } from "../constants/allConstants";
import { jwtToken } from "../constants/jwtToken";

export const updateEmailApi = async (worksman_id: any, email: any): Promise<any> => {
    try {

        const config = {
            headers:{
                Authorization: await jwtToken()
            }
        }

        let data = await axios.put(`${envDevUrl}/UpdateWorksmanDetailsApi`, {
            worksman_id: worksman_id,
            email: email
        },config)
        return data

    } catch (exception) {
        throw exception
    }
}