import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const deleteScheduleJobApi = async(worksman_schedule_id:any):Promise<any> => {
        try {
            const config = {
                headers: {
                    schedule_id: worksman_schedule_id,
                    Authorization: await jwtToken()
                }
            }                            
            const { data } = await axios.delete(`${envDevUrl}/DeleteWorkmanScheduleApi`, config)

            return data
                            
        } catch (exception) {
            throw exception
        }                         
}