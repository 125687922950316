import Box from "@mui/material/Box";
import { Dialog, Slide, SwipeableDrawer } from "@mui/material";
import { forwardRef } from "react";
import { ScheduleTimingsComp } from "./ScheduleTimingsComp";

const Transition = forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ScheduleDrawerComp = ({
  handleClose,
  date,
  open,
  handleClickOpen,
  submitWorksmanCurrentAvailability,
  storeWorksmanCurrentAvailability,  
  worksManCurrentAvailability
}:any) => {
  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        onOpen={handleClickOpen}
        className="desktop_drawer"
      >
         <ScheduleTimingsComp
          handleClose={handleClose}
          date={date}
          handleClickOpen={handleClickOpen}
          storeWorksmanCurrentAvailability={storeWorksmanCurrentAvailability}
          submitWorksmanCurrentAvailability={submitWorksmanCurrentAvailability}
          worksManCurrentAvailability={worksManCurrentAvailability}
      
        /> 
      </SwipeableDrawer>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="mobile_drawer"
      >
         <ScheduleTimingsComp
          handleClose={handleClose}
          date={date}
          handleClickOpen={handleClickOpen}
          storeWorksmanCurrentAvailability={storeWorksmanCurrentAvailability}
          submitWorksmanCurrentAvailability={submitWorksmanCurrentAvailability}
          worksManCurrentAvailability={worksManCurrentAvailability}
      
        />
      </Dialog>
    </Box>
  );
};
