import axios from "axios"
import { envDevUrl } from "../constants/allConstants"
import { jwtToken } from "../constants/jwtToken"

export const updateBankDetailsApi = async(data:any,status:any,state:any):Promise<any> => {
    const {name,sortCode,accountNumber,bankName}:any = data
    const {bank_detail_id}:any = state[0]
    try {
        const config = {
              worksman_id: status[0].worksman_id,
              account_name :name,
              sort_code:sortCode,
              account_number :accountNumber,
              bank_name : bankName,
              bank_detail_id : bank_detail_id
        }
        const data = {
            headers:{
                Authorization: await jwtToken()
            }
        }
        const updateBankdetails = await axios.put(`${envDevUrl}/UpdateBankDetailsApi`,config,data)
        return updateBankdetails
    } catch (exception) {
        throw exception
    }
}