import { singleUserDataTypes } from "../constants/action_type";

const initialState = {
    single_user_data: {}
}

export const SingleUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case singleUserDataTypes.GET_SINGLE_USER_DATA:
            state = {
                ...state,
                single_user_data: action.payload
            }
    }
    return state
}